import React from 'react';

const InputGroup = (props) => {
    const swatch = props.swatch;

    let myComponentStyle = {};

    if (swatch !== undefined) {
        myComponentStyle = {
            width: '15px', 
            height: '15px', 
            backgroundColor: `${swatch}`, 
            marginRight: '0.5rem', 
            borderRadius: '0.25rem'
        }
    }

    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label d-flex align-items-center">
                <div style={myComponentStyle}></div>
                {props.label}
            </label>
            <div className="input-group">
                <input 
                    id={props.id} 
                    {...props.attributes}
                    type={props.type} 
                    className="form-control" 
                    value={props.value}
                    onChange={props.changed}
                />
                <span className="input-group-text">{props.addOnText}</span>
            </div>
            <div id={props.helpTextId} className="form-text">{props.helpText}</div>
        </div>
    )
}

export default InputGroup;