import React from 'react';
import styles from './Step.module.css';

const Step = (props) => {
    const currentStep = props.currentStepIndex;
    const index = props.index;
    let badge = props.index;
    let badgeClass = null;

    if (currentStep > index || currentStep === props.maxSteps) {
        
        badgeClass = ' WS-bg-done';
        badge = (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
        )

    } else if (currentStep === index) {

        badgeClass = ' WS-bg-active';

    }

    return (
        <React.Fragment>
            <div className={`${styles.row} d-flex`}>
                <div className="me-3">
                    <div className={`${styles['step-badge']} rounded-circle ${badgeClass}`}>
                        {badge}
                    </div>
                </div>
                <div>
                    <div className={styles.title}>
                        {props.title}
                    </div>
                    <div className={styles.subTitle}>
                        {props.subTitle}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step;