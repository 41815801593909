import React from 'react';
import SectionTitle from '../../Typography/SectionTitle';

const Review = ({ formData, servicesData }) => {
    const customerName = formData.formFields.customerName.value === '' ? 'N/A' : formData.formFields.customerName.value;
    const customerNumber = formData.formFields.customerNumber.value === '' ? 'N/A' : formData.formFields.customerNumber.value;
    const serviceLocationAddress = formData.formFields.serviceLocationAddress.value === '' ? 'N/A' : formData.formFields.serviceLocationAddress.value;
    const collectionAreaWidth = formData.formFields.collectionAreaWidth.value === '' ? 'N/A' : `${formData.formFields.collectionAreaWidth.value} feet`;
    const collectionAreaLength = formData.formFields.collectionAreaLength.value === '' ? 'N/A' : `${formData.formFields.collectionAreaLength.value} feet`;
    const collectionAreaHeight = formData.formFields.collectionAreaHeight.value === '' ? 'N/A' : `${formData.formFields.collectionAreaHeight.value} feet`;
    // const doorwayPosition = formData.formFields.doorwayPosition.value === '' ? 'None' : formData.formFields.doorwayPosition.value;
    // const doorwayWidth = formData.formFields.doorwayWidth.value === '' ? 'N/A' : `${formData.formFields.doorwayWidth.value} feet`;
    // const doorwayHeight = formData.formFields.doorwayHeight.value === '' ? 'N/A' : `${formData.formFields.doorwayHeight.value} feet`;

    let formElementsArray = [];

    for (let key in servicesData.options) {
        formElementsArray.push({
            id: key,
            name: servicesData.options[key]['name'],
            volume: servicesData.options[key]['volume'],
            pickupFrequency: servicesData.options[key]['pickupFrequency'],
            pickupFrequencyOptions: servicesData.options[key]['pickupFrequencyOptions'],
            checked: servicesData.options[key]['checked']
        });
    }

    formElementsArray = formElementsArray.filter(item => item.checked);    

    let services = null;
    
    services = formElementsArray.map((service, index) => {
        const volume = service.volume === '' ? 'N/A' : `${service.volume} cubic yards`;
        const pickupOption = service.pickupFrequencyOptions.find(option => option.id === service.pickupFrequency);
        const pickupFrequency = service.pickupFrequency === '' ? 'Any' : pickupOption.name;

        return (
            <div key={index} className="card WS-card-grey">
                <div className="mb-2">
                    <strong>{service.name}</strong>
                </div>
                <div className="WS-small-text">
                    <div className="mb-1">
                        <strong>Pick up frequency:</strong> {pickupFrequency}
                    </div>
                    <div className="mb-1">
                        <strong>Weekly volume:</strong> {volume}
                    </div>
                </div>
            </div>
        )
    });

    return (
        <React.Fragment>
            <SectionTitle>
                Customer information
            </SectionTitle>
            <div className="WS-subTitle">Customer name</div>
            <div className="mb-3">{customerName}</div>
            <div className="WS-subTitle">Customer number</div>
            <div className="mb-3">{customerNumber}</div>
            <div className="WS-subTitle">Service location address</div>
            <div className="mb-3">{serviceLocationAddress}</div>
            <SectionTitle>
                Service information
            </SectionTitle>
            <div>
                {services}
            </div>
            <SectionTitle>
                Collection area information
            </SectionTitle>
            <div className="WS-subTitle">Collection area width</div>
            <div className="mb-3">{collectionAreaWidth}</div>
            <div className="WS-subTitle">Collection area length</div>
            <div className="mb-3">{collectionAreaLength}</div>
            <div className="WS-subTitle">Collection area height</div>
            <div className="mb-3">{collectionAreaHeight}</div>
            {/* <div className="WS-subTitle">Doorway position</div>
            <div className="mb-3 text-capitalize">{doorwayPosition}</div>
            <div className="WS-subTitle">Doorway width</div>
            <div className="mb-3">{doorwayWidth}</div>
            <div className="WS-subTitle">Doorway height</div>
            <div className="mb-3">{doorwayHeight}</div> */}
        </React.Fragment>
    )
}

export default Review;