import React, { useState } from 'react';
import ServiceCard from './ServiceCard';
import Button from '../UI/Button/Button';

const ServiceCards = (props) => {
    const filteredData = props.services.filter(item => item.id === props.services[0].id);
    const [filteredServices, setFilteredServices] = useState(filteredData);
    const defaultServices = props.services;
    const [activeFilter, setActiveFilter] = useState(props.services[0].id);

    const filterHandler = (serviceId) => {
        const tempData = [...defaultServices];
        const filteredData = tempData.filter(item => item.id === serviceId);

        setActiveFilter(serviceId);
        setFilteredServices(filteredData);
    }

    let serviceFilterButtons = null;

    serviceFilterButtons = defaultServices.map((service, index) => {
        let state = '';

        if (activeFilter === service.id) {
            state = ' WS-button-toggle-active';
        }

        return <Button
            key={index}
            classes={`btn WS-button-toggle me-2${state}`}
            type="button"
            loading={false}
            valid={true}
            clickHandler={() => filterHandler(service.id)}
        >
            {service.name}
        </Button>
    });

    let serviceCards = null;

    serviceCards = filteredServices.map((service, index) => {
        return <ServiceCard
            key={index}
            serviceId={service.id}
            name={service.name}
            pickupFrequency={service.pickupFrequency}
            containers={service.containers}
        />
    });

    return (
        <React.Fragment>
            <div>
                {serviceFilterButtons}
            </div>
            {serviceCards}
        </React.Fragment>
    )
}

export default ServiceCards;