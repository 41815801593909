import { colorPalette, getNested } from './utils';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

const getContainerIndex = (estimatedVolume, containerArr, weeklyPickups) => {
    let index = 0;
    let volume = 0;
    const reversedContainerArr = [...containerArr].reverse();
    estimatedVolume = estimatedVolume * (1 / weeklyPickups);
    
    // Traverse reverse sorted array to find the largest container to meet estimated weekly volume
    for (let i = 0; i < reversedContainerArr.length; i++) {
        volume = reversedContainerArr[i].volume;
        // console.log(`volume: ${volume}`);
        // console.log(`estimatedVolume: ${estimatedVolume}`);
        if (volume >= estimatedVolume) {
            index = (reversedContainerArr.length - 1) - i;
            break;
        }
    }

    if (index === 0 && estimatedVolume > volume) {
        // Traverse regular sorted array to find the NEXT largest (start at index 1 instead of 0) container 
        // where double capacity meets estimated weekly volume to help reduce footprint
        for (let i = 1; i < containerArr.length; i++) {
            volume = containerArr[i].volume * 2;
            // console.log(`volume: ${volume}`);
            // console.log(`estimatedVolume: ${estimatedVolume}`);
            if (volume >= estimatedVolume) {
                index = i;
                break;
            }
        }
    }

    return index;
}

const calculateMonthlyPrice = (costPerPickup, numBins, numBinsDiscountArr, numPickupsDiscountArr, pickupFrequencyId, priceMultiplier) => {
    // console.log('*** calculateMonthlyPrice CALLED ***');
    // console.log(`costPerPickup: ${costPerPickup}`);
    // console.log(`numBins: ${numBins}`);
    // console.log(`numBinsDiscountArr:`);
    // console.log(numBinsDiscountArr);
    // console.log(`numPickupsDiscountArr:`);
    // console.log(numPickupsDiscountArr);
    // console.log(`pickupFrequencyId: ${pickupFrequencyId}`);
    // console.log(`priceMultiplier: ${priceMultiplier}`);

    let numBinsDiscount = 1;
    let weeklyPickupsDiscount = 1;

    weeklyPickupsDiscount = getNested(numPickupsDiscountArr, pickupFrequencyId);

    // Loop thru discounts by bin count to find the higest available discount
    const sortedNumBinsDiscountArr = sortBy(numBinsDiscountArr, 'numBins');

    for (const discount of sortedNumBinsDiscountArr) {
        if (numBins >= discount.numBins) {
            numBinsDiscount = discount.numBinsDiscount;
            weeklyPickupsDiscount = getNested(discount.numPickupsDiscount, pickupFrequencyId);
        } else {
            break;
        }
    }

    // console.log(`numBinsDiscount: ${numBinsDiscount}`);
    // console.log(`weeklyPickupsDiscount: ${weeklyPickupsDiscount}`);
    costPerPickup = (((costPerPickup * numBins) * numBinsDiscount) * priceMultiplier) * weeklyPickupsDiscount;

    return costPerPickup;
}

class DLLNode{
    constructor(serviceTypeId, serviceTypeName, serviceTypeEstimatedVolume, palette, counter, maxCounter) {
        this.id = serviceTypeId;
        this.name = serviceTypeName;
        this.volumePerWeek = serviceTypeEstimatedVolume;
        this.palette = palette;
        this.counter = counter;
        this.maxCounter = maxCounter;
        this.next = null;
        this.prev = null;
    }
}

class DoublyLinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
        this.length = 0;
    }
    push(serviceTypeId, serviceTypeName, serviceTypeEstimatedVolume, palette, counter, maxCounter) {
        var newNode = new DLLNode(serviceTypeId, serviceTypeName, serviceTypeEstimatedVolume, palette, counter, maxCounter);
        if(this.length === 0){
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.tail.next = newNode;
            newNode.prev = this.tail;
            this.tail = newNode;
        }
        this.length++;
        return this;
    }
    findPermutations() {
        const data = [];
        let tmpData = [];
        const head = this.head;
        const tail = this.tail;

        function traverse(node){
// console.log(data);
// console.log('traverse start');
            tmpData.push({
                counter: node.counter,
                maxCounter: node.maxCounter,
                id: node.id,
                name: node.name,
                volumePerWeek: node.volumePerWeek,
                palette: node.palette
            });
// console.log(`head.counter: ${head.counter}`);
// console.log(`head.maxCounter: ${head.maxCounter}`);
// console.log(`node.counter: ${node.counter}`);
// console.log(`node.maxCounter: ${node.maxCounter}`);
// console.log(`tail.counter: ${tail.counter}`);
// console.log(`tail.maxCounter: ${tail.maxCounter}`);

            if (node.next) {
// console.log('THERE IS A NEXT NODE');
                if (node.next === tail) {
                    if (node.next.counter === node.next.maxCounter) {
                        if (node.counter === node.maxCounter && node !== head) {
                            node.counter = 0;
                        } else {
                            node.counter++;
                        }
                    }
                } else {
                    if (node.next.counter === node.next.maxCounter && node.next.next.counter === node.next.next.maxCounter) {
                        if (node.counter === node.maxCounter && node !== head) {
                            node.counter = 0;
                        } else {
                            node.counter++;
                        }
                    }
                }
                traverse(node.next);

            } else {
// console.log('NO NEXT NODE!!!');
// console.log('PUSH DATA TO ARRAY');
                data.push(tmpData);
                tmpData = [];

                if (head.counter <= head.maxCounter) {
                    if (node.counter < node.maxCounter) { 
                        node.counter++;
                        traverse(head);
                    } else if (node.counter >= node.maxCounter) {
                        if (node !== head) {
                            node.counter = 0;
                            traverse(head);
                        }
                    }
                }
            }
        }
        traverse(head);
        return data;
    }
}

class Node {
    constructor(x, y, width, length, filled) {
        this.id = null;
        this.name = null;
        this.x = x;
        this.y = y;
        this.width = width;
        this.length = length;
        this.actualWidth = null;
        this.actualLength = null;
        this.manoeuvreFactor = null;
        this.isFilled = filled;
        this.serviceTypeId = null;
        this.serviceTypeName = null;
        this.hex = null;
        this.childLeft = null;
        this.childRight = null;
    }
}

class BinaryTree {
    constructor(){
        this.root = null;
    }
    insert(x, y, width, length, filled) {
        const newNode = new Node(x, y, width, length, filled);
        if (this.root === null){
            this.root = newNode;
            return this;
        }
    }
    PriceContainers(serviceTypeId, ratingInfo, weeklyPickups, pickupFrequencyId, priceMultiplier) {
        const data = {};
        function traverse(node){
            if (node.id !== null && node.serviceTypeId === serviceTypeId) {
                if (!data[node.id]) {
                    data[node.id] = {
                        name: node.name,
                        quantity: 1,
                        area: node.actualWidth * node.actualLength
                    };
                } else {
                    data[node.id].quantity = data[node.id].quantity + 1;
                    data[node.id].area = data[node.id].area + (node.actualWidth * node.actualLength);
                }
            }
            if (node.childLeft) traverse(node.childLeft);
            if (node.childRight) traverse(node.childRight);
        }
        traverse(this.root);

        for (const container in data) {
            const numBins = data[container].quantity;
            const costPerPickup = getNested(ratingInfo, serviceTypeId, container, 'costPerPickup');
            const numBinsDiscountArr = getNested(ratingInfo, serviceTypeId, container, 'numBinsDiscount');
            const numPickupsDiscountArr = getNested(ratingInfo, serviceTypeId, container, 'numPickupsDiscount');
            
            let price = null;
            price = calculateMonthlyPrice(costPerPickup, numBins, numBinsDiscountArr, numPickupsDiscountArr, pickupFrequencyId, priceMultiplier);

            if (price === undefined) {
                price = 0;
            }
            data[container].price = price;
        }

        return data;
    }
    GetPlacedContainers() {
        const data = [];
        function traverse(node){
            data.push({
                id: node.id,
                name: node.name,
                serviceId: node.serviceTypeId,
                serviceTypeName: node.serviceTypeName,
                hex: node.hex,
                x: node.x,
                y: node.y,
                width: node.width,
                length: node.length,
                actualWidth: node.actualWidth,
                actualLength: node.actualLength,
                manoeuvreFactor: node.manoeuvreFactor,
                isFilled: node.isFilled
            });
            if (node.childLeft) traverse(node.childLeft);
            if (node.childRight) traverse(node.childRight);
        }
        traverse(this.root);
        return data;
    }
    PlaceContainer(id, name, width, length, volume, serviceTypeName, hex, manoeuvreFactor, serviceTypeId, serviceTypeWasteVolume, weeklyPickups) {
        let newNode = null;
        const actualWidth = width;
        const actualLength = length;

        if (manoeuvreFactor > 0) {
            width = width * manoeuvreFactor;
            length = length * manoeuvreFactor;
        }
        
        // adjust container volume based on frequency of pickup
        volume = volume * weeklyPickups;

        function traverse(node) {
            let newX = null;
            let newY = null;
            let newWidth = null;
            let newLength = null;

            const wasteVolumeCapacity = serviceTypeWasteVolume[serviceTypeId].capacity;
            const wasteVolumeEstimate = serviceTypeWasteVolume[serviceTypeId].estimate;
            
            // console.log(`wasteVolumeCapacity: ${wasteVolumeCapacity}`);
            // console.log(`wasteVolumeEstimate: ${wasteVolumeEstimate}`);

            if (wasteVolumeCapacity >= wasteVolumeEstimate) {
                // console.log('waste capacity exceeds estimate - STOP TRAVERSE');
            } else {
                // console.log('waste capacity is less than estimate - CONTINUE TRAVERSE');
                if (!node.isFilled) { 
                    // console.log('this NODE is NOT filled');
                    if (width <= node.width && length <= node.length) { 
                        // Container FITS place and create child nodes if smaller than current node
                        // console.log('CONTAINER FITS INTO NODE!');
                        if (width < node.width) {
                            // CREATE a new child node and store in the left property of current node
                            newX = node.x + width;
                            newY = node.y;
                            newWidth = node.width - width;
                            if (length < node.length) {
                                newLength = length;
                            } else {
                                newLength = node.length;
                            }
                            newNode = new Node(newX, newY, newWidth, newLength, false);
                            if(node.childLeft) {
                                newNode.childLeft = node.childLeft;
                            }
                            node.childLeft = newNode;
                        }
                        if (length < node.length) {
                            // CREATE a new child node and store in the right property of current node
                            newX = node.x;
                            newY = node.y + length;
                            newWidth = node.width;
                            newLength = node.length - length;
                            newNode = new Node(newX, newY, newWidth, newLength, false);
                            if(node.childRight) {
                                newNode.childRight = node.childRight;
                            }
                            node.childRight = newNode;
                        }
                        // PLACE the container into the current node (i.e. update existing attributes)
                        node.id = id;
                        node.name = name;
                        node.width = width;
                        node.length = length;
                        node.actualWidth = actualWidth;
                        node.actualLength = actualLength;
                        node.manoeuvreFactor = manoeuvreFactor;
                        node.isFilled = true;
                        node.serviceTypeId = serviceTypeId;
                        node.serviceTypeName = serviceTypeName;
                        node.hex = hex;
                        serviceTypeWasteVolume[serviceTypeId].capacity = wasteVolumeCapacity + volume;
                    }
                }

                if (node.childLeft) traverse(node.childLeft);
                if (node.childRight) traverse(node.childRight);
            }
        }
        traverse(this.root);
        return this;
    }
    SetReservedArea(position, width, length){
        let newNode1 = null;
        let newNode2 = null;
        let newNode3 = null;
        let newX = null;
        let newY = null;
        let newWidth = null;
        let newLength = null;
        const node = this.root;
        const originalWidth = node.width;
        const originalLength = node.length;

        if (node){
            switch (position) {
                case 'top':
                    // Update root node size
                    newWidth = (node.width - width) / 2;
                    newLength = length;
                    node.width = newWidth;
                    node.length = newLength;

                    // Create a new left child node (newNode1) of the root 
                    newX = node.width;
                    newY = node.y;
                    newWidth = width;
                    newLength = length;

                    newNode1 = new Node(newX, newY, newWidth, newLength, true);
                    node.childLeft = newNode1;
                    node.childLeft.id = 'reserved';
                    node.childLeft.name = 'Doorway Reserved Area';
                    node.childLeft.serviceTypeName = 'RESERVED';

                    // Create a new right child node (newNode2) of the root 
                    newX = node.x;
                    newY = node.y + node.length;
                    newWidth = originalWidth;
                    newLength = originalLength - node.length;

                    newNode2 = new Node(newX, newY, newWidth, newLength, false);
                    node.childRight = newNode2;

                    // Create a new left child node (newNode3) of newNode1
                    newX = newNode1.x + width;
                    newY = newNode1.y;
                    newWidth = (originalWidth - width) / 2;
                    newLength = length;

                    newNode3 = new Node(newX, newY, newWidth, newLength, false);
                    newNode1.childLeft = newNode3;
                    break;
                case 'right':
                    // Update root node size
                    newWidth = node.width - width;
                    node.width = newWidth;

                    // Create a new left child node (newNode1) of the root 
                    newX = node.width;
                    newY = node.y;
                    newWidth = width;
                    newLength = (node.length - length) / 2;

                    newNode1 = new Node(newX, newY, newWidth, newLength, false);
                    node.childLeft = newNode1;

                    // Create a new right child node (newNode2) of newNode1
                    newX = node.width;
                    newY = newY + newLength;
                    newWidth = width;
                    newLength = length;

                    newNode2 = new Node(newX, newY, newWidth, newLength, true);
                    newNode1.childRight = newNode2;
                    newNode1.childRight.id = 'reserved';
                    newNode1.childRight.name = 'Doorway Reserved Area';
                    newNode1.childRight.serviceTypeName = 'RESERVED';

                    // Create a new right child node (newNode3) of newNode2
                    newX = node.width;
                    newY = newY + newLength;
                    newWidth = width;
                    newLength = (node.length - length) / 2;

                    newNode3 = new Node(newX, newY, newWidth, newLength, false);
                    newNode2.childRight = newNode3;
                    break;
                case 'bottom':
                    // Update root node size
                    newLength = node.length - length;
                    node.length = newLength;

                    // Create a new right child node (newNode1) of the root 
                    newX = node.x;
                    newY = node.y + node.length;
                    newWidth = (originalWidth - width) / 2;
                    newLength = originalLength - node.length;

                    newNode1 = new Node(newX, newY, newWidth, newLength, false);
                    node.childRight = newNode1;

                    // Create a new left child node (newNode2) of newNode1 
                    newX = newNode1.x + newNode1.width;
                    newY = newNode1.y;
                    newWidth = width;
                    newLength = length;

                    newNode2 = new Node(newX, newY, newWidth, newLength, true);
                    newNode1.childLeft = newNode2;
                    newNode1.childLeft.id = 'reserved';
                    newNode1.childLeft.name = 'Doorway Reserved Area';
                    newNode1.childLeft.serviceTypeName = 'RESERVED';

                    // Create a new left child node (newNode3) of newNode2
                    newX = newNode2.x + newNode2.width;
                    newY = newNode2.y;
                    newWidth = (originalWidth - width) / 2;
                    newLength = length;

                    newNode3 = new Node(newX, newY, newWidth, newLength, false);
                    newNode2.childLeft = newNode3;
                    break;
                case 'left':
                    // Update root node size
                    newWidth = width;
                    newLength = (node.length - length) / 2;
                    node.width = newWidth;
                    node.length = newLength;

                    // Create a new left child node (newNode1) of the root 
                    newX = node.width;
                    newY = node.y;
                    newWidth = originalWidth - width;
                    newLength = originalLength;

                    newNode1 = new Node(newX, newY, newWidth, newLength, false);
                    node.childLeft = newNode1;

                    // Create a new right child node (newNode2) of the root 
                    newX = node.x;
                    newY = node.y + node.length;
                    newWidth = width;
                    newLength = length;

                    newNode2 = new Node(newX, newY, newWidth, newLength, true);
                    newNode1.childRight = newNode2;
                    newNode1.childRight.id = 'reserved';
                    newNode1.childRight.name = 'Doorway Reserved Area';
                    newNode1.childRight.serviceTypeName = 'RESERVED';

                    // Create a new right child node (newNode3) of newNode2
                    newX = node.x;
                    newY = newY + newLength;
                    newWidth = width;
                    newLength = node.length;

                    newNode3 = new Node(newX, newY, newWidth, newLength, false);
                    newNode2.childRight = newNode3;
                    break;
                default:
                    break;
            }
        }
        return this;
    }
}

export const getBestQuote = (customerData, containerData, ratingInfo, pickupFrequencyData) => {
    console.log('[getBestQuote CALLED]');

    console.log('[customerData]');
    console.log(customerData);

    console.log('[containerData]');
    console.log(containerData);

    console.log('[ratingInfo]');
    console.log(ratingInfo);

    console.log('[pickupFrequencyData]');
    console.log(pickupFrequencyData);

    const quotes = [];
    const storageAreaWidth = customerData.collectionArea.width;
    const storageAreaLength = customerData.collectionArea.length;
    const storageAreaHeight = customerData.collectionArea.height;
    const doorwayPosition = customerData.doorway.position;
    const doorwayWidth = customerData.doorway.width;
    const doorwayHeight = customerData.doorway.height;
    // const doorwayReservedAreaWidth = customerData.doorway.reservedAreaWidth;
    // const doorwayReservedAreaLength = customerData.doorway.reservedAreaLength;
    const CONTAINER_CATEGORY_WEEKLY_ESTIMATE_THRESHOLD = 2;
    const CONTAINER_CLEARANCE_FACTOR = 0.6;
    const RESERVED_AREA_THOROUGHFARE_FACTOR = 0.6;
    const doorwayData = {
        position: null,
        width: null,
        height: null
    };
    doorwayData.position = doorwayPosition;
    doorwayData.width = doorwayWidth;
    doorwayData.height = doorwayHeight;
    let doorwayReservedAreaWidth = null;
    let doorwayReservedAreaLength = null;
    let containers = [...containerData];
    let pickupSchedules = {...pickupFrequencyData};

    switch (doorwayPosition) {
        case 'top':
            doorwayReservedAreaWidth = doorwayWidth;
            doorwayReservedAreaLength = storageAreaLength * RESERVED_AREA_THOROUGHFARE_FACTOR;
            break;
        case 'right':
            doorwayReservedAreaWidth = storageAreaWidth * RESERVED_AREA_THOROUGHFARE_FACTOR;
            doorwayReservedAreaLength = doorwayWidth;
            break;
        case 'bottom':
            doorwayReservedAreaWidth = doorwayWidth;
            doorwayReservedAreaLength = storageAreaLength * RESERVED_AREA_THOROUGHFARE_FACTOR;
            break;
        case 'left':
            doorwayReservedAreaWidth = storageAreaWidth * RESERVED_AREA_THOROUGHFARE_FACTOR;
            doorwayReservedAreaLength = doorwayWidth;
            break;
        default:
            break;
    }

    // Filter containers based on collection area ceiling height restrictions
    if (storageAreaHeight > 0) {
        containers = containers.filter(item => (item.height + (item.length * CONTAINER_CLEARANCE_FACTOR)) <= storageAreaHeight);

        console.log('[FILTERED CONTAINERS BY ROOM HEIGHT]');
        console.log(containers);
    }

    // Filter containers that do not fit through the doorway (height)
    if (doorwayPosition) {
        containers = containers.filter(item => item.height <= doorwayHeight);
        containers = containers.filter(item => (item.width <= doorwayWidth) || (item.length <= doorwayWidth));

        console.log('[FILTERED CONTAINERS BY DOORWAY]');
        console.log(containers);
    }

    // Create an array of required Service Types sorted by size (volume) in descending (reverse) order
    const serviceTypes = [...customerData.serviceTypes];
    const sortedServiceTypes = sortBy(serviceTypes, 'volumePerWeek').reverse();

    console.log('[SORTED_SERVICE_TYPES]');
    console.log(sortedServiceTypes);

    // FILTER pickupSchedules SERVICES based on services required for this quote
    // ALSO populate an object of prefereed pick up frequency Ids with service_id as the key
    const tempPickupSchedules = {};
    const servicePreferredPickupFrequency = {};

    for (const service of sortedServiceTypes) {
        tempPickupSchedules[service.id] = pickupSchedules[service.id];
        servicePreferredPickupFrequency[service.id] = service.pickupFrequencyId;
    }
    pickupSchedules = {...tempPickupSchedules}

    console.log('FILTERED pickupSchedules by required SERVICES:');
    console.log(pickupSchedules);

    // FILTER pickupSchedules PICK UP OPTIONS based on customer preference
    for (const key in pickupSchedules) {
        const preferredPickupSchedule = servicePreferredPickupFrequency[key];
        console.log(`preferredPickupSchedule: ${preferredPickupSchedule}`);

        const filteredArr = pickupSchedules[key].filter(item => item.id.includes(preferredPickupSchedule))
        pickupSchedules[key] = filteredArr;
    }

    console.log('FILTERED pickupSchedules by PREFERRED PICK UP FREQUENCY:');
    console.log(pickupSchedules);

    // populate a doubly linked list with service type and pickup schedule info so that we
    // can find all permutations available

    const dll = new DoublyLinkedList();

    for (const serviceType of sortedServiceTypes) {
        const serviceTypeId = serviceType.id;
        const serviceTypeName = serviceType.name;
        const serviceTypeEstimatedVolume = serviceType.volumePerWeek;
        const palette = serviceType.palette;
        const counter = 0;
        const maxCounter = pickupSchedules[serviceTypeId].length - 1;

        if (maxCounter >= 0) {
            dll.push(serviceTypeId, serviceTypeName, serviceTypeEstimatedVolume, palette, counter, maxCounter);
        }
    }
    console.log(dll);

    const permutationsArr = dll.findPermutations();
    console.log(permutationsArr);

    const areaTree = new BinaryTree();
    areaTree.insert(0, 0, storageAreaWidth, storageAreaLength, false);
    areaTree.SetReservedArea(doorwayPosition, doorwayReservedAreaWidth, doorwayReservedAreaLength);

    let loopCount = 0;

    for (const permutation of permutationsArr) {
        // console.log('*********************************');
        // console.log('*    PERMUTATION LOOP START     *');
        // console.log('*********************************');
        // console.log(permutation);
        
        loopCount++;

        let permutationIsValid = true;
        let totalPermutationPrice = 0;
        let totalPermutationArea = 0;
        const quoteSummaryServiceTypes = [];
        const treeQuote = cloneDeep(areaTree);

        // Create an object for storing waste volume for all service types
        const serviceTypeWasteVolume = {};

        for (const serviceType of permutation) {
            // console.log('*********************************');
            // console.log('*    SERVICE TYPE LOOP START    *');
            // console.log('*********************************');
            // console.log(serviceType);
            
            const serviceTypeId = serviceType.id;
            const serviceTypeName = serviceType.name;
            const serviceTypeEstimatedVolume = serviceType.volumePerWeek;
            const palette = serviceType.palette;
            const pickupOptionIndex = serviceType.counter;
            
            // console.log(`pickupOptionIndex: ${pickupOptionIndex}`);
    
            // Filter containers based on eligibility by service type
            let serviceContainers = [];
            serviceContainers = containers.filter(item => item.services.includes(serviceTypeId));

            const pickupFrequencyId = pickupSchedules[serviceTypeId][pickupOptionIndex]['id'];
            const pickupSchedule = pickupSchedules[serviceTypeId][pickupOptionIndex]['name'];
            const weeklyPickups = pickupSchedules[serviceTypeId][pickupOptionIndex]['weeklyPickups'];
            const priceMultiplier = pickupSchedules[serviceTypeId][pickupOptionIndex]['priceMultiplier'];
            
            // console.log(`pickupSchedule: ${pickupSchedule}`);
            // console.log(`weeklyPickups: ${weeklyPickups}`);

            // SET waste volume estimate for each service type/pickup option and set capacity to ZERO
            serviceTypeWasteVolume[serviceTypeId] = {
                name: serviceTypeName,
                estimate: serviceTypeEstimatedVolume,
                capacity: 0
            }
            
            // Don't place bins if estimate is 0
            if (serviceTypeEstimatedVolume > 0) {
                // FILTER containers 
                //  => default to Cart if estimated volume is less than the defined threshold: 3455d7b8-3132-490e-b364-25fdb8666208
                //  => default to Front Load if estimated volume is greater than or equal to the defined threshold: 10401a7c-5ab0-4d36-be79-131f516ff19e
                //      --- EXCEPTION --- filtering results if no containers available
                if (serviceTypeEstimatedVolume < CONTAINER_CATEGORY_WEEKLY_ESTIMATE_THRESHOLD) {
                    const filteredContainers = serviceContainers.filter(item => item.categoryId === '3455d7b8-3132-490e-b364-25fdb8666208');

                    if (filteredContainers.length > 0) {
                        serviceContainers = cloneDeep(filteredContainers);
                    }
                } else {
                    const filteredContainers = serviceContainers.filter(item => item.categoryId === '10401a7c-5ab0-4d36-be79-131f516ff19e');

                    if (filteredContainers.length > 0) {
                        serviceContainers = cloneDeep(filteredContainers);
                    }
                }

                // console.log('[FILTERED CONTAINERS by SERVICE TYPE]');
                // console.log(serviceContainers);

                const containerIndex = getContainerIndex(serviceTypeEstimatedVolume, serviceContainers, weeklyPickups);

                // Iterate through Containers array starting from container_index
                for (let i = containerIndex; i < serviceContainers.length; i++) {
                    // console.log(serviceContainers[i]);
                    const thisContainerId = serviceContainers[i].id;
                    const thisContainerName = serviceContainers[i].name;
                    const thisContainerWidth = serviceContainers[i].width;
                    const thisContainerLength = serviceContainers[i].length;
                    const thisContainerManoeuvreFactor = serviceContainers[i].manoeuvreFactor;
                    const thisContainerVolume = serviceContainers[i].volume;
                    // const thisContainerHex = colorPalette(palette, serviceContainers[i].paletteIndex);
                    const thisContainerHex = colorPalette(palette, 1);

                    // console.log('[PlaceContainer CALLED]');
                    treeQuote.PlaceContainer(
                        thisContainerId,
                        thisContainerName, 
                        thisContainerWidth, 
                        thisContainerLength, 
                        thisContainerVolume, 
                        serviceTypeName, 
                        thisContainerHex, 
                        thisContainerManoeuvreFactor, 
                        serviceTypeId, 
                        serviceTypeWasteVolume,
                        weeklyPickups
                    );

                    if (serviceTypeWasteVolume[serviceTypeId].capacity > serviceTypeWasteVolume[serviceTypeId].estimate) {
                        // console.log('waste capacity exceeds estimate - BREAK');
                        break;
                    }
                }
            }

            // summarize for each pickup schedule option
            const containerSummary = treeQuote.PriceContainers(serviceTypeId, ratingInfo, weeklyPickups, pickupFrequencyId, priceMultiplier); 

            let totalServicePrice = 0;
            let totalContainerArea = 0;

            for (const key in containerSummary) {
                const price = containerSummary[key].price;
                totalServicePrice += price;
                const area = containerSummary[key].area;
                totalContainerArea += area;
            }

            quoteSummaryServiceTypes.push({
                id: serviceTypeId,
                name: serviceTypeName,
                pickupFrequencyId: pickupFrequencyId,
                pickupSchedule: pickupSchedule,
                estimatedWeeklyVolume: serviceTypeWasteVolume[serviceTypeId].estimate,
                weeklyCapacity: serviceTypeWasteVolume[serviceTypeId].capacity,
                containers: containerSummary,
                palette: palette,
                totalServicePrice: totalServicePrice,
                totalContainerArea: totalContainerArea
            });

            totalPermutationPrice = totalPermutationPrice + totalServicePrice;
            totalPermutationArea = totalPermutationArea + totalContainerArea;

            // Only continue with this permutation if this pickup option meets volume requirements for the service
            if (serviceTypeWasteVolume[serviceTypeId].capacity < serviceTypeWasteVolume[serviceTypeId].estimate) {
                // console.log('** NOT ENOUGH CAPACITY - ABORT permutation loop **');
                permutationIsValid = false;
                break;
            }
    
            // END OF SERVICE TYPE LOOP
        }

        if (permutationIsValid) {
            quotes.push({
                loopCount: loopCount,
                collectionAreaData: treeQuote.GetPlacedContainers(),
                quoteSummaryData: quoteSummaryServiceTypes,
                totalPrice: totalPermutationPrice,
                totalArea: totalPermutationArea
            });
        }
    }

    const sortedQuotes = sortBy(quotes, ['totalPrice','totalArea']);
    console.log('CHOOSE BEST OPTION...');
    console.log(sortedQuotes);

    const bestOption = sortedQuotes[0];
    console.log('best option:');
    console.log(bestOption);

    let response;

    if (bestOption) {
        response = {
            status: 'ok',
            collectionAreaData: bestOption.collectionAreaData,
            doorway: doorwayData,
            quoteSummaryData: bestOption.quoteSummaryData
        };
    } else {
        response = {
            status: 'ok',
            collectionAreaData: areaTree.GetPlacedContainers(),
            doorway: doorwayData,
            quoteSummaryData: []
        };
    }

    console.log(response);

    return response;
}