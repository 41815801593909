import React from 'react';
import SectionTitle from '../Typography/SectionTitle';
import InputGroup from '../UI/InputGroup';
import Select from '../UI/Select';
import { colorPalette } from '../../utils';

const ServiceDetails = ({ quoteData, pickupFrequencyData, changeHandler, serviceChangedHandler }) => {
    // const defaultDoorwayOption = {
    //     value: '',
    //     name: 'None'
    // };
    
    // const doorwayOptions = [
    //     {
    //         id: 'top',
    //         name: 'Top'
    //     },
    //     {
    //         id: 'right',
    //         name: 'Right'
    //     },
    //     {
    //         id: 'bottom',
    //         name: 'Bottom'
    //     },
    //     {
    //         id: 'left',
    //         name: 'Left'
    //     }
    // ];

    let volumeFormElems = null;

    volumeFormElems = quoteData.services.map((service, index) => {
        const hex = colorPalette(service.palette, 1);

        return (
            <InputGroup
                key={index}
                type="number"
                id={`volume-${service.serviceId}`}
                label={`${service.name} weekly volume`}
                swatch={hex}
                addOnText="Cubic yards"
                value={service.weeklyVolume}
                changed={(event) => serviceChangedHandler(event, service.serviceId)}
            />
        )
    });

    const defaultOption = {
        value: '',
        name: 'Any'
    };

    let pickupFrequencyFormElems = null;
    
    pickupFrequencyFormElems = quoteData.services.map((service, index) => {
        const hex = colorPalette(service.palette, 1);
        const options = [];

        if (Array.isArray(pickupFrequencyData[service.serviceId])) {
            for (const elem of pickupFrequencyData[service.serviceId]) {
                options.push({
                    id: elem.id,
                    name: elem.name
                });
            }
        }

        return (
            <Select
                key={index}
                id={`pickup-${service.serviceId}`}
                label={`${service.name} pick up frequency`}
                swatch={hex}
                options={options}
                defaultOption={defaultOption}
                value={service.pickupFrequencyId}
                changed={(event) => serviceChangedHandler(event, service.serviceId)}
            />
        )
    });

    return (
        <React.Fragment>
            <SectionTitle>
                Service details
            </SectionTitle>
            <InputGroup
                type="number"
                id="collectionAreaWidth"
                label="Collection area width"
                helpTextId="collectionAreaWidth-help"
                addOnText="Feet"
                value={quoteData.collectionAreaWidth}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="collectionAreaLength"
                label="Collection area length"
                helpTextId="collectionAreaLength-help"
                addOnText="Feet"
                value={quoteData.collectionAreaLength}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="collectionAreaHeight"
                label="Collection area height"
                helpTextId="collectionAreaHeight-help"
                addOnText="Feet"
                value={quoteData.collectionAreaHeight}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            {volumeFormElems}
            {pickupFrequencyFormElems}
            {/* <Select
                id="doorwayPosition"
                label="Doorway position"
                helpTextId="doorwayPosition-help"
                options={doorwayOptions}
                defaultOption={defaultDoorwayOption}
                value={quoteData.doorwayPosition}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="doorwayWidth"
                label="Doorway width"
                helpTextId="doorwayWidth-help"
                addOnText="Feet"
                value={quoteData.doorwayWidth}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="doorwayHeight"
                label="Doorway height"
                helpTextId="doorwayHeight-help"
                addOnText="Feet"
                value={quoteData.doorwayHeight}
                changed={(event) => changeHandler(event, event.target.id)}
            /> */}
        </React.Fragment>
    )
}

export default ServiceDetails;