import React from 'react';
import SectionTitle from '../Typography/SectionTitle';

const CustomerDetails = ({ quoteData }) => {
    const customerName = quoteData.customerName === null ? 'N/A' : quoteData.customerName;
    const customerNumber = quoteData.customerNumber === null ? 'N/A' : quoteData.customerNumber;
    const formattedAddress = quoteData.formattedAddress === null ? 'N/A' : quoteData.formattedAddress;

    return (
        <React.Fragment>
            <SectionTitle
                classes="WS-margin-top"
            >
                Customer details
            </SectionTitle>
            <div className="WS-subTitle">
                Customer name
            </div>
            <div className="mb-3">
                {customerName}
            </div>
            <div className="WS-subTitle">
                Customer number
            </div>
            <div className="mb-3">
                {customerNumber}
            </div>
            <div className="WS-subTitle">
                Service location address
            </div>
            <div>
                {formattedAddress}
            </div>
        </React.Fragment>
    )
}

export default CustomerDetails;