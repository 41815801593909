import React from 'react';
import Step from './Step';

const Steps = (props) => {
    let currentStepIndex = 0;

    for (let i = 0; i < props.steps.length; i++) {
        if (props.steps[i].step === props.currentStep) {
            currentStepIndex = i + 1;
        }
    }
    
    const steps = props.steps.map((step, index) => {
        return <Step 
            key={index}
            title={step.title}
            subTitle={step.subTitle}
            index={index + 1}
            currentStepIndex={currentStepIndex}
            maxSteps={props.steps.length}
        />
    });

    return (
        <React.Fragment>
            {steps}
        </React.Fragment>
    )
}

export default Steps;