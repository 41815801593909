import React from 'react';
import SectionTitle from '../../Typography/SectionTitle';
import Input from '../../UI/Input';

const CustomerInfo = ({ formData, changeHandler }) => {
    const nameAttributes = {
        'aria-describedby': `customerName-help`
    };

    const numberAttributes = {
        'aria-describedby': `customerNumber-help`
    };

    return (
        <React.Fragment>
            <SectionTitle>
                Customer information
            </SectionTitle>
            <Input
                type="text"
                attributes={nameAttributes}
                id="customerName"
                label="Customer name"
                helpText={`Please enter the customer name.`}
                helpTextId="customerName-help"
                value={formData.formFields.customerName.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <Input
                type="text"
                attributes={numberAttributes}
                id="customerNumber"
                label="Customer number (Optional)"
                helpText={`If this quote is for an existing customer, please enter the customer number.`}
                helpTextId="customerNumber-help"
                value={formData.formFields.customerNumber.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
        </React.Fragment>
    )
}

export default CustomerInfo;