import React from 'react';
import currency from 'currency.js';
import { colorPalette, formatDecimal } from '../../utils';
import Swatch from '../UI/Swatch';

const QuoteSummaryItem = (props) => {
    const containers = props.containers;
    const containersArr = [];
    let items = 'N/A';
    
    for (const key in containers) {
        containersArr.push({
            name: containers[key].name,
            quantity: containers[key].quantity,
            price: containers[key].price
        });
    }

    if (containersArr.length > 0) {
        items = containersArr.map((item, index) => {
            return <li key={index}>({item.quantity}) x {item.name} - {currency(item.price).format()}</li>
            // return <li key={index}>({item.quantity}) x {item.name}</li>
        });
    }

    let classes = 'card px-3 py-2 mb-2';
    
    if (props.estimatedWeeklyVolume > props.weeklyCapacity) {
        classes += ' bg-danger text-white';
    }

    return (
        <div className={classes}>
            <div className="mb-3 d-flex align-items-center">
                <Swatch 
                    hex={colorPalette(props.palette, 1)}
                />
                <strong>{props.name}</strong>
            </div>
            <div className="WS-small-text">
                <div className="row align-items-start mb-1">
                    <div className="col-4 WS-list-label">
                        Pick up frequency:
                    </div>
                    <div className="col-8">
                        {props.pickupSchedule}
                    </div>
                </div>
                <div className="row align-items-start mb-1">
                    <div className="col-4 WS-list-label">
                        Weekly volume:
                    </div>
                    <div className="col-8">
                        {formatDecimal(props.estimatedWeeklyVolume, 2)} Cubic yards
                    </div>
                </div>
                <div className="row align-items-start mb-1">
                    <div className="col-4 WS-list-label">
                        Service:
                    </div>
                    <div className="col-8">
                        <ul className="mb-0 WS-no-decoration">{items}</ul>
                    </div>
                </div>
                <div className="row align-items-start mb-1">
                    <div className="col-4 WS-list-label">
                        Weekly capacity:
                    </div>
                    <div className="col-8">
                        {formatDecimal(props.weeklyCapacity, 2)} Cubic yards
                    </div>
                </div> 
                <div className="row align-items-start mb-1">
                    <div className="col-4 WS-list-label">
                        Monthly service fee:
                    </div>
                    <div className="col-8">
                        {currency(props.totalServicePrice).format()}
                    </div>                 
                </div> 
            </div>
        </div>
    )
}

export default QuoteSummaryItem;