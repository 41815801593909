import React from 'react';
import currency from 'currency.js';
import QuoteSummaryItem from './QuoteSummaryItem';

const QuoteSummary = (props) => {
    let subtotal = 0;
    
    const services = props.quoteSummaryData.map((serviceType, index) => {
        subtotal = subtotal + serviceType.totalServicePrice;
        return <QuoteSummaryItem
            key={index}
            name={serviceType.name}
            estimatedWeeklyVolume={serviceType.estimatedWeeklyVolume}
            pickupSchedule={serviceType.pickupSchedule}
            weeklyCapacity={serviceType.weeklyCapacity}
            totalServicePrice={serviceType.totalServicePrice}
            containers={serviceType.containers}
            palette={serviceType.palette}
        />
    });

    const servicesLength = props.quoteSummaryData.length;

    return (
        <React.Fragment>
            {props.quoteSummaryData.length > 0 
                ?
                    <div className="text-left">
                        <div className="mb-2">{servicesLength} services in your quote</div>   
                        {services}
                        <div className="col-sm pt-2 pl-4 pr-0">
                            <div className="text-muted">Subtotal:</div>
                            <div className="h2 font-weight-bold mb-0">CA{currency(subtotal).format()}</div>
                            <small>monthly</small>
                        </div>
                    </div>
                :
                    <div className="alert alert-warning d-flex align-items-start" role="alert">
                        <div className="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                            </svg>
                        </div>
                        <div>
                            WasteSaver was unable to generate a quote summary. The collection area may not be large enough to sufficiently accommodate the storage area needed to meet your weekly service volume requirements. Try adjusting your service details and refresh your quote.
                        </div>
                    </div>
            }
        </React.Fragment>
    )
}

export default QuoteSummary;