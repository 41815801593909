import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import Button from './Button/Button';

const ModalOverlay = ({ title, closeHandler, submitHandler, previousButton, nextButton, children }) => {
    useEffect(() => {
        const quoteModal = document.getElementById('defaultModal')
        quoteModal.addEventListener('hide.bs.modal', closeHandler);

        return () => {
            quoteModal.removeEventListener('hide.bs.modal', closeHandler);
        }
    }, [closeHandler]);

    const attributes = {
        'id': 'hiddenModalClose',
        'data-bs-dismiss': 'modal'
    };

    const closeButton = (
        <Button
            classes="d-none"
            type="button"
            attributes={attributes}
            loading={false}
            valid={true}
        >
            Close
        </Button>
    )

    return (
        <form onSubmit={submitHandler} autoComplete="off" noValidate>
            <div className="modal" id="defaultModal" tabIndex="-1" aria-labelledby="defaultModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content modal-content-vh-100">      
                        <div className="modal-header">
                            <div className="modal-title h5 d-flex align-items-center" id="defaultModalLabel">{title}</div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            {children}
                        </div>
                        <div className="modal-footer">
                            {closeButton}
                            {previousButton}
                            {nextButton}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

const portalElement = document.getElementById('modal-root');

const Modal = ({ title, closeHandler, submitHandler, previousButton, nextButton, children }) => {
    return (
        <React.Fragment>
            {ReactDom.createPortal(
                <ModalOverlay
                    title={title}
                    closeHandler={closeHandler}
                    submitHandler={submitHandler}
                    previousButton={previousButton}
                    nextButton={nextButton}
                >
                    {children}
                </ModalOverlay>, portalElement
            )}
        </React.Fragment>
    )
}

export default Modal;