import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Login from './containers/Login/Login';
import Header from './components/Layout/Header';
import Drawer from './components/Layout/Drawer';
import Menu from './components/Layout/Menu';
import SystemMessage from './components/Layout/SystemMessage';
import Content from './components/Layout/Content';
import Footer from './components/Layout/Footer';
// import Dashboard from './containers/Dashboard';
// import Customers from './containers/Customers';
import Pricing from './containers/Pricing';
import Quotes from './containers/Quotes';
import Quote from './containers/Quote';
import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary';
import SystemMessageProvider from './store/SystemMessageProvider';
import PageNotFound from './containers/PageNotFound';

const App = () => {
    const [userData, setUserData] = useState(() => {
        const userId = sessionStorage.getItem('userId');
       
        let isLoggedIn = false;

        if (userId !== null && userId !== '') {
            isLoggedIn = true;
        }

        return { loggedIn: isLoggedIn };
    });

    const login = () => {
        setUserData(userData => ({...userData, loggedIn: true}));
    }

    const logout = () => {
        setUserData(userData => ({...userData, loggedIn: false}));

        sessionStorage.removeItem('token');
        sessionStorage.removeItem('expirationDate');
        sessionStorage.removeItem('userId');
    }

    const checkAuthTimeout = (expirationTime) => {
        console.log(`[checkAuthTimeout called] Expiration time: ${expirationTime} msec`)
        
        setTimeout(() => {
            logout();
        }, expirationTime);
    }

    return (
        <Router>
            {!userData.loggedIn 
                ? 
                    <Login 
                        login={login} 
                        checkAuthTimeout={checkAuthTimeout} 
                    />
                : 
                    <SystemMessageProvider>
                        <Header 
                            logout={logout}
                        />
                        <Drawer />
                        <div className="d-none d-sm-block">
                            <Menu />
                        </div>
                        <SystemMessage />
                        <Content>
                            <Switch>
                                <Route exact path="/">
                                    <ErrorBoundary>
                                        {/* <GetQuote /> */}
                                        <Redirect to="/quotes" />
                                    </ErrorBoundary>
                                </Route>
                                {/* <Route exact path="/dashboard">
                                    <Dashboard />
                                </Route> */}
                                <Route exact path="/quotes">
                                    <Quotes />
                                </Route>
                                <Route exact path="/quotes/:quoteId">
                                    <Quote />
                                </Route>
                                {/* <Route exact path="/customers">
                                    <Customers />
                                </Route> */}
                                <Route exact path="/pricing">
                                    <Pricing />
                                </Route>
                                <Route path="*">
                                    <PageNotFound />
                                </Route>
                            </Switch>
                        </Content>
                        <Footer />
                    </SystemMessageProvider>
            }  
        </Router>
    );
}

export default App;