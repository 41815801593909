import React from 'react';

const Input = (props) => {
    let inputElement = null;
    let label = null;
    let labelClasses = 'App-custom-label';
    
    switch (props.elementConfig.type) {
        case 'text':
            if (props.elementLabel) {
                labelClasses += ' text-start';
                label = (
                    <label className={labelClasses} htmlFor={props.elementConfig.id}>{props.elementLabel}</label>
                );
            }

            inputElement = (
                <div className="form-floating">
                    <input 
                        className={props.elementClasses}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed}
                    />
                    {label}
                </div>    
            )
            break;
        case 'password':
            if (props.elementLabel) {
                labelClasses += ' text-start';
                label = (
                    <label className={labelClasses} htmlFor={props.elementConfig.id}>{props.elementLabel}</label>
                );
            }

            inputElement = (
                <div className="form-floating">
                    <input 
                        className={props.elementClasses}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed}
                    />
                    {label}
                </div>
            )
            break;
        case 'select':
            if (props.elementLabel) {
                labelClasses += ' text-start';
                label = (
                    <label className={labelClasses} htmlFor={props.elementConfig.id}>{props.elementLabel}</label>
                );
            }

            let options = null;

            options = props.options.map((option, index) => {
                return <option key={index} value={option.optionValue}>{option.optionName}</option>
            });

            inputElement = (
                <div className="form-floating">
                    <select 
                        className={props.elementClasses}
                        // defaultValue={'any'}
                        {...props.elementConfig}
                        value={props.value}
                        onChange={props.changed}
                    >
                        {options}
                    </select>
                    {label}
                </div>    
            )
            break;
        case 'checkbox':
            if (props.elementLabel) {
                labelClasses += ' custom-control-label';
                label = (
                    <label className={labelClasses} htmlFor={props.elementConfig.id}>{props.elementLabel}</label>
                );
            }

            inputElement = (
                <div className="card p-2 mb-2">
                    <div className="custom-control custom-checkbox text-start">
                        <input 
                            className={props.elementClasses}
                            {...props.elementConfig}
                            value={props.value}
                            onChange={props.changed}
                        />
                        {label}
                    </div>
                </div>
            )
            break;
        case 'radio':
            if (props.elementLabel) {
                labelClasses += ' custom-control-label';
                label = (
                    <label className={labelClasses} htmlFor={props.elementConfig.id}>{props.elementLabel}</label>
                );
            }

            inputElement = (
                <div className="card p-2 mb-2">
                    <div className="custom-control custom-radio text-start">
                        <input 
                            className={props.elementClasses}
                            {...props.elementConfig}
                            value={props.value}
                            onChange={props.changed}
                        />
                        {label}
                    </div>
                </div>
            )
            break;
        default:
            break;
    }

    return (
        <React.Fragment>
            {/* {props.elementLabel &&
                <label htmlFor={props.id}>{props.elementLabel}</label>
            } */}
            {inputElement}
        </React.Fragment>
    )
}

export default Input;