import React from 'react';
import QuotesTableRow from './QuotesTableRow';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../Typography/SectionTitle';
import BlankSlate from '../Typography/BlankSlate';

const QuotesTable = (props) => {
    const history = useHistory();

    const detailClickHandler = (event, id) => {
        history.push(`/quotes/${id}`);
    }

    let quoteRows = null;

    if (Array.isArray(props.quotes)) {
        quoteRows = props.quotes.map((quote, index) => {
            return <QuotesTableRow
                key={index}
                customerName={quote.customerName}
                customerNumber={quote.customerNumber}
                status={quote.status}
                quoteId={quote.quoteId}
                accountRep={quote.accountRep}
                dateCreated={quote.dateCreated}
                dateModified={quote.dateModified}
                totalPrice={quote.totalPrice}
                detailClickHandler={(event) => detailClickHandler(event, quote.quoteId)}
            />
        });
    }

    if (props.quotes.length === 0) {
        console.log('no quotes');
    }

    return (
        <React.Fragment>
            <SectionTitle>
                Pending quotes
            </SectionTitle>
            {props.quotes.length === 0
                ?   
                    <BlankSlate>
                        There are no pending quotes available.
                    </BlankSlate>
                :
                    <div className="table-responsive">
                        <table className="table table-hover align-middle WS-table">
                            <thead>
                                <tr>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Customer number</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Monthly service fee</th>
                                    <th scope="col">Account rep</th>
                                    <th scope="col">Created</th>
                                    <th scope="col">Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quoteRows}
                            </tbody>
                        </table>
                    </div>
            }
        </React.Fragment>
    )
}

export default QuotesTable;