import React from 'react';
import PlacesAutocomplete from '../../UI/PlacesAutocomplete/PlacesAutocomplete';
import SectionTitle from '../../Typography/SectionTitle';

const AddressInfo = ({ formData, changeHandler }) => {
    const config = {
        elementConfig: {
            id: 'serviceLocationAddress',
            type: 'text',
            placeholder: '',
            name: 'place',
            'aria-describedby': 'addressHelp'
        },
        elementClasses: 'form-control',
    };

    return (
        <React.Fragment>
            <SectionTitle>
                Address information
            </SectionTitle>
            <div className="mb-3">
                <label htmlFor="serviceLocationAddress" className="form-label">Service location address</label>
                <PlacesAutocomplete 
                    id={config.elementConfig.id}
                    elementConfig={config.elementConfig}
                    elementClasses={config.elementClasses}
                    value={formData.formFields.serviceLocationAddress.value}
                    changeHandler={changeHandler}
                />
                 <div id="addressHelp" className="form-text">Please enter the service location address.</div>
            </div>
        </React.Fragment>
    )
}

export default AddressInfo;