import React from 'react';
import dayjs from 'dayjs';
import currency from 'currency.js';

const QuotesTableRow = (props) => {
    const status = props.status;
    const customerName = props.customerName;
    const customerNumber = props.customerNumber;
    const accountRep = props.accountRep;
    const dateCreated = props.dateCreated === null ? '' : dayjs(props.dateCreated).format('MM/DD/YYYY');
    const dateModified = props.dateModified === null ? '' : dayjs(props.dateModified).format('MM/DD/YYYY');
    const totalPrice = props.totalPrice;
    const detailClickHandler = props.detailClickHandler;

    return (
        <React.Fragment>
            <tr onClick={detailClickHandler} className="WS-clickable">
                <td>{customerName}</td>
                <td>{customerNumber}</td>
                <td className="text-capitalize">{status}</td>
                <td>{currency(totalPrice).format()}</td>
                <td>{accountRep}</td>
                <td>{dateCreated}</td>
                <td>{dateModified}</td>
            </tr>
        </React.Fragment>
    )
}

export default QuotesTableRow;