import React from 'react';
import styles from './SectionTitle.module.css';

const FormSectionTitle = (props) => {
    return (
        <div className={`${styles.title} h5 border-bottom ${props.classes}`}>
            {props.children}
        </div>
    )
}

export default FormSectionTitle;