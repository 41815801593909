import React from 'react';
import styles from './Swatch.module.css';

const Swatch = (props) => {
    return (
        <div className={`${styles.swatch}`} style={{
            backgroundColor: `${props.hex}`
        }}></div>
    )
}

export default Swatch;