import React from 'react';
import { Loader } from '@googlemaps/js-api-loader';

const PlacesAutocomplete = ({ id, elementConfig, elementClasses, value, changeHandler }) => {
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const loader = new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places']
    });

    loader
        .load()
        .then(() => {
            const input = document.getElementById(id);
            
            const options = {
                componentRestrictions: { country: 'ca' },
                fields: ['formatted_address', 'place_id', 'geometry'],
                types: ['address'],
            };
            
            const autocomplete = new window.google.maps.places.Autocomplete(input, options);
            
            const onPlaceChanged = () => {
                const place = autocomplete.getPlace();
                let lat = null;
                let lng = null;

                if (!place.geometry) {
                    // console.log('No lat/lng');
                } else {
                    lat = place.geometry.location.lat();
                    lng = place.geometry.location.lng();
                    // console.log(`Lat: ${lat}`);
                    // console.log(`Lng: ${lng}`);
                }

                // console.log(`Address: ${input.value}`);

                changeHandler(input.value, id, lat, lng);
            }

            autocomplete.addListener('place_changed', onPlaceChanged);
        })
        .catch(e => {
            // do something
        });

    let inputElement = null;

    inputElement = (
        <input 
            className={elementClasses}
            {...elementConfig}
            defaultValue={value}
            // onChange={(event) => changeHandler(event, event.target.id)}
        />
    )
    
    return (
        <React.Fragment>
            {inputElement}
        </React.Fragment>
    )
}

export default PlacesAutocomplete;