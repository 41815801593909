import React from 'react';
import styles from './Content.module.css';

const Content = (props) => {
    return (
        <div id="content" className={`${styles.content}`}>
            <div className={`${styles['content-body']}`}>
                {props.children}
            </div>
        </div>
    )
}

export default Content;