import React, { useState, useEffect, useContext } from 'react';
import SystemMessageContext from '../../../store/system-message-context';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import MyModal from '../../UI/Modal';
import Steps from './Steps';
import CustomerInfo from './CustomerInfo';
import AddressInfo from './AddressInfo';
import Services from './Services';
import ServiceInfo from './ServiceInfo';
import CollectionAreaInfo from './CollectionAreaInfo';
import Access from './Access';
import Review from './Review';
import styles from './NewQuote.module.css';
import Button from '../../UI/Button/Button';
import { getBestQuote } from '../../../binPacking'

const NewQuote = (props) => {
    const systemMessageCtx = useContext(SystemMessageContext); 
    const history = useHistory();

    const PROVIDER_ID = '4594c7bd-8cf2-4858-bd28-69ddc385d693';

    const [containerData, setContainerData] = useState({});
    const [priceData, setPriceData] = useState({});
    const [pickupFrequencyData, setPickupFrequencyData] = useState({});
    const [currentStep, setCurrentStep] = useState('customer-info');
    const [servicesForm, setServicesForm] = useState({
        options: {},
        loading: false,
        valid: true,
        errorMessage: ''
    });

    const defaultFormValues = {
        customerName: {
            value: '',
            valid: true,
            validation: {
                required: false
            }
        },
        customerNumber: {
            value: '',
            valid: true,
            validation: {
                required: false
            }
        },
        serviceLocationAddress: {
            value: '',
            lat: '',
            lng: '',
            valid: true,
            validation: {
                required: false
            }
        },
        collectionAreaWidth: {
            value: '25',
            valid: true,
            validation: {
                required: false
            }
        },
        collectionAreaLength: {
            value: '20',
            valid: true,
            validation: {
                required: false
            }
        },
        collectionAreaHeight: {
            value: '',
            valid: true,
            validation: {
                required: false
            }
        },
        doorwayPosition: {
            value: '',
            valid: true,
            validation: {
                required: false
            }
        },
        doorwayWidth: {
            value: '',
            valid: true,
            validation: {
                required: false
            }
        },
        doorwayHeight: {
            value: '',
            valid: true,
            validation: {
                required: false
            }
        }
    };
    
    const [userForm, setUserForm] = useState({
        formFields: defaultFormValues,
        loading: false,
        valid: true,
        errorMessage: ''
    });

    useEffect(() => {
        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

        const getContainerData = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/containers/?providerId=${PROVIDER_ID}`);
      
                setContainerData(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        const getPriceData = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/pricing/?providerId=${PROVIDER_ID}`);
                
                setPriceData(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        const getPickupFrequencyData = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/pickup-frequency/?providerId=${PROVIDER_ID}`);

                setPickupFrequencyData(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }
        
        const getServices = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/services/${PROVIDER_ID}`);
                
                let tempTypes = {};

                data.body.forEach(service => {
                    tempTypes[service.id] = {
                        name: service.name,
                        palette: service.palette,
                        pickupFrequencyOptions: service.pickupFrequency,
                        pickupFrequency: '',
                        volume:'',
                        checked: false
                    };
                });
                
                setServicesForm(servicesForm => ({...servicesForm, options: tempTypes}));
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getServices();
        getContainerData();
        getPriceData();
        getPickupFrequencyData();
    }, []);

    const steps = [
        {
            step: 'customer-info',
            title: 'Customer information',
            subTitle: 'Enter customer information'
        },
        {
            step: 'address-info',
            title: 'Address information',
            subTitle: 'Enter service location address'
        },
        {
            step: 'services',
            title: 'Services',
            subTitle: 'Choose your services'
        },
        {
            step: 'service-info',
            title: 'Service details',
            subTitle: 'Volume and pick up frequency'
        },
        {
            step: 'collection-area',
            title: 'Collection area',
            subTitle: 'Enter collection area details'
        },
        // {
        //     step: 'access',
        //     title: 'Access restrictions',
        //     subTitle: 'Enter access information'
        // },
        {
            step: 'review',
            title: 'Completed',
            subTitle: 'Review and submit'
        }
    ];

    let stepComponent = null;
    let previousButton = null;
    let nextButton = null;
    let previousStep = null;
    let nextStep = null;
    let progress = 0;

    // Create a steps array to use for determining current step positioning for a progress bar
    const stepsProgress = [
        'customer-info',
        'address-info',
        'services',
        'service-info',
        'collection-area',
        // 'access',
        'review'
    ];

    if (stepsProgress.indexOf(currentStep) !== -1) {
        progress = (stepsProgress.indexOf(currentStep) / stepsProgress.length * 100);
    }
    
    const tempIndex = stepsProgress.indexOf(currentStep);
    const arrLength = stepsProgress.length - 1;

    if (tempIndex === arrLength) {
        progress = 100;
    }

    const previousStepHandler = (step) => {
        setUserForm(userForm => ({...userForm, loading: true}));

        setTimeout(() => {
            setCurrentStep(currentStep => (step));
            setUserForm(userForm => ({...userForm, loading: false}));
        }, 500);
    }

    const nextStepHandler = (step) => {
        setCurrentStep(currentStep => (step));
    }

    const inputChangedHandler = (event, inputId) => {
        const type = event.target.type;
        let newValue = event.target.value;

        if (type === 'number' && newValue <= 0) {
            newValue = '';
        }

        const updatedForm = {...userForm.formFields};
        const updatedFormElement = {...updatedForm[inputId]};
        updatedFormElement.value = newValue;
        updatedForm[inputId] = updatedFormElement;

        setUserForm(userForm => ({...userForm, formFields: updatedForm}));
    }

    // create a special change handler for the Google placesautocomplete widget
    const changePlaceHandler = (value, inputId, lat, lng) => {
        const updatedForm = {...userForm.formFields};
        const updatedFormElement = {...updatedForm[inputId]};

        updatedFormElement.value = value;
        updatedFormElement.lat = lat;
        updatedFormElement.lng = lng;
        updatedForm[inputId] = updatedFormElement;

        setUserForm(userForm => ({...userForm, formFields: updatedForm}));
    }

    const servicesChangedHandler = (event, inputId) => {
        const target = event.target;
        const checked = target.checked;
        const updatedForm = {...servicesForm.options};
        const updatedFormElement = {...updatedForm[inputId]};

        updatedFormElement.checked = checked;
        updatedForm[inputId] = updatedFormElement;

        setServicesForm(servicesForm => ({...servicesForm, options: updatedForm}));
    }

    const serviceInfoChangedHandler = (event, inputId) => {
        const target = event.target;
        let value = target.value;
        const updatedForm = {...servicesForm.options};
        const updatedFormElement = {...updatedForm[inputId]};
        
        if (target.type === 'number') {
            if (value <= 0) {
                value = '';
            }
            updatedFormElement.volume = value;
        } else if (target.type === 'select-one') {
            updatedFormElement.pickupFrequency = value;
        }
        
        updatedForm[inputId] = updatedFormElement;

        setServicesForm(servicesForm => ({...servicesForm, options: updatedForm}));
    }
    
    nextButton = (
        <Button
            classes="btn btn-primary ms-2 WS-btn-min-width"
            type="submit"
            loading={userForm.loading}
            valid={userForm.valid}
        >
            Next
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right ms-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
        </Button>
    )

    switch (currentStep) {
        case 'customer-info':
            nextStep = 'address-info';
            stepComponent = (
                <CustomerInfo 
                    formData={userForm}
                    changeHandler={inputChangedHandler}
                />
            )

            const attributes = {
                'data-bs-dismiss': 'modal'
            };

            previousButton = (
                <Button
                    classes="btn btn-outline-secondary WS-btn-min-width"
                    type="button"
                    attributes={attributes}
                    loading={userForm.loading}
                    valid={userForm.valid}
                >
                    Cancel
                </Button>
            )
            break;
        case 'address-info':
            previousStep = 'customer-info';
            nextStep = 'services';
            stepComponent = (
                <AddressInfo 
                    formData={userForm}
                    changeHandler={changePlaceHandler}
                />
            )
            previousButton = (
                <Button
                    classes="btn btn-outline-secondary mr-2 WS-btn-min-width"
                    type="button"
                    loading={userForm.loading}
                    valid={userForm.valid}
                    clickHandler={() => previousStepHandler(previousStep)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    Previous
                </Button>
            )
            break;
        case 'services':
            previousStep = 'address-info';
            nextStep = 'service-info';
            stepComponent = (
                <Services 
                    formData={servicesForm}
                    changeHandler={servicesChangedHandler}
                />
            )
            previousButton = (
                <Button
                    classes="btn btn-outline-secondary mr-2 WS-btn-min-width"
                    type="button"
                    loading={userForm.loading}
                    valid={userForm.valid}
                    clickHandler={() => previousStepHandler(previousStep)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    Previous
                </Button>
            )
            break;
        case 'service-info':
            previousStep = 'services';
            nextStep = 'collection-area';
            stepComponent = (
                <ServiceInfo 
                    formData={servicesForm}
                    changeHandler={serviceInfoChangedHandler}
                />
            )
            previousButton = (
                <Button
                    classes="btn btn-outline-secondary mr-2 WS-btn-min-width"
                    type="button"
                    loading={userForm.loading}
                    valid={userForm.valid}
                    clickHandler={() => previousStepHandler(previousStep)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    Previous
                </Button>
            )
            break;
        case 'collection-area':
            previousStep = 'service-info';
            // nextStep = 'access';
            nextStep = 'review';
            stepComponent = (
                <CollectionAreaInfo
                    formData={userForm}
                    changeHandler={inputChangedHandler}
                />
            )
            previousButton = (
                <Button
                    classes="btn btn-outline-secondary mr-2 WS-btn-min-width"
                    type="button"
                    loading={userForm.loading}
                    valid={userForm.valid}
                    clickHandler={() => previousStepHandler(previousStep)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    Previous
                </Button>
            )
            break;
        case 'access':
            previousStep = 'collection-area';
            nextStep = 'review';
            stepComponent = (
                <Access
                    formData={userForm}
                    changeHandler={inputChangedHandler}
                />
            )
            previousButton = (
                <Button
                    classes="btn btn-outline-secondary mr-2 WS-btn-min-width"
                    type="button"
                    loading={userForm.loading}
                    valid={userForm.valid}
                    clickHandler={() => previousStepHandler(previousStep)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    Previous
                </Button>
            )
            break;
        case 'review':
            // previousStep = 'access';
            previousStep = 'collection-area';
            nextStep = 'submit';
            stepComponent = (
                <Review 
                    formData={userForm}
                    servicesData={servicesForm}
                />
            )
            previousButton = (
                <Button
                    classes="btn btn-outline-secondary mr-2 WS-btn-min-width"
                    type="button"
                    loading={userForm.loading}
                    valid={userForm.valid}
                    clickHandler={() => previousStepHandler(previousStep)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                    Previous
                </Button>
            )

            // override nextButton for the final step
            nextButton = (
                <Button
                    classes="btn btn-success ms-2 WS-btn-min-width"
                    type="submit"
                    loading={userForm.loading}
                    valid={userForm.valid}
                >
                    Submit
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2 ms-2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </Button>
            )
            break;
        default:
            break;
    }

    const closeModalHandler = () => {
        setCurrentStep(currentStep => ('customer-info'));

        // clear services
        const updatedForm = {...servicesForm.options};

        for (let key in updatedForm) {
            updatedForm[key]['checked'] = false;
            updatedForm[key]['volume'] = '';
            updatedForm[key]['pickupFrequency'] = '';
        }

        setServicesForm(servicesForm => ({...servicesForm, options: updatedForm}));
        setUserForm(userForm => ({...userForm, loading: false, formFields: defaultFormValues}));
    }

    // handle form submission
    const quoteFormHandler = (event, currentStep) => {
        event.preventDefault();

        console.log('form submitted');
        console.log(`currentStep: ${currentStep}`);
        console.log(`nextStep: ${nextStep}`);

        setUserForm(userForm => ({...userForm, loading: true}));

        switch (currentStep) {
            case 'review':
                console.log('POST');
                console.log(userForm);
                console.log(servicesForm);
                
                const formData = userForm.formFields;

                let servicesArr = [];
                let services = [];

                for (let key in servicesForm.options) {
                    if (servicesForm.options[key]['checked']) {
                        console.log(`key: ${key} is checked... add to array`)
                        servicesArr.push({
                            id: key,
                            volume: Number(servicesForm.options[key]['volume']),
                            pickupFrequency: servicesForm.options[key]['pickupFrequency']
                        });

                        services.push({
                            id: key,
                            name: servicesForm.options[key]['name'],
                            volumePerWeek: Number(servicesForm.options[key]['volume']),
                            palette: servicesForm.options[key]['palette'],
                            pickupFrequencyId: servicesForm.options[key]['pickupFrequency']
                        });
                    }
                }
                
                const userId = sessionStorage.getItem('userId');

                const postData = {
                    providerId: PROVIDER_ID,
                    userId: userId,
                    customerName: formData.customerName.value,
                    customerNumber: formData.customerNumber.value,
                    formattedAddress: formData.serviceLocationAddress.value,
                    lat: formData.serviceLocationAddress.lat,
                    lng: formData.serviceLocationAddress.lng,
                    collectionAreaWidth: Number(formData.collectionAreaWidth.value),
                    collectionAreaLength: Number(formData.collectionAreaLength.value),
                    collectionAreaHeight: Number(formData.collectionAreaHeight.value),
                    doorwayPosition: formData.doorwayPosition.value,
                    doorwayWidth: Number(formData.doorwayWidth.value),
                    doorwayHeight: Number(formData.doorwayHeight.value),
                    services: servicesArr
                };

                const postQuote = async () => {
                    const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

                    try {
                        const { data } = await axios.post(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quotes`, postData);

                        // validate response
                        if (data.statusCode === 201) {
                            // setUserForm(userForm => ({...userForm, loading: false}));
                            // document.getElementById('hiddenModalClose').click();
                            
                            const quoteId = data.body.quoteId;
                            // systemMessageCtx.setSystemMessage('Your quote was created successfully.', 'success');
                            // history.push(`/quotes/${quoteId}`);
                            updateQuoteSummary(quoteId);
                        }
                    } catch(error) {
                        console.log(`error: ${error}`);
                        setUserForm(userForm => ({...userForm, loading: false}));
                        document.getElementById('hiddenModalClose').click();

                        systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
                        history.push(`/quotes`);
                    }
                }
        
                postQuote();

                // UPDATE QUOTE SUMMARY AND CONTAINER DATA
                const updateQuoteSummary = async (quoteId) => {
                    const customerData = {
                        collectionArea: {
                            width: Number(formData.collectionAreaWidth.value),
                            length: Number(formData.collectionAreaLength.value),
                            height: Number(formData.collectionAreaHeight.value)
                        },
                        doorway: {
                            position: formData.doorwayPosition.value,
                            width: Number(formData.doorwayWidth.value),
                            height: Number(formData.doorwayHeight.value)
                        },
                        serviceTypes: services
                    };
                    
                    // RUN BIN PACKING ALGORITHM
                    const response = getBestQuote(customerData, containerData, priceData, pickupFrequencyData);
                
                    const putContainers = async () => {
                        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

                        const containerNodes = response.collectionAreaData;
                        const containers = containerNodes.filter(item => item.id !== null && item.id !== 'reserved');
                        const reservedNodes = containerNodes.filter(item => item.id === 'reserved');

                        const putData = {
                            quoteId: quoteId,
                            containers: containers,
                            reservedNodes: reservedNodes
                        };
                        
                        // console.log(putData);

                        try {
                            const { data } = await axios.put(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quote-containers`, putData);
            
                            // validate response
                            if (data.statusCode === 200) {
                                putSummary(quoteId);
                            }
                        } catch(error) {
                            // console.log(`error: ${error}`);
                            // setQuoteIsLoading(false);
            
                            // systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
                            console.log(`error: ${error}`);
                            setUserForm(userForm => ({...userForm, loading: false}));
                            document.getElementById('hiddenModalClose').click();

                            systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
                            history.push(`/quotes`);
                        }
                    }

                    const putSummary = async (quoteId) => {
                        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

                        const services = response.quoteSummaryData;

                        const putData = {
                            quoteId: quoteId,
                            services: services
                        };
                        
                        // console.log(putData);

                        try {
                            const { data } = await axios.put(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quote-summary`, putData);
            
                            // validate response
                            if (data.statusCode === 200) {
                                setUserForm(userForm => ({...userForm, loading: false}));
                                document.getElementById('hiddenModalClose').click();
                                
                                systemMessageCtx.setSystemMessage('Your quote was created successfully.', 'success');
                                history.push(`/quotes/${quoteId}`);
                            }
                        } catch(error) {
                            // console.log(`error: ${error}`);
                            // setQuoteIsLoading(false);
            
                            // systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
                            console.log(`error: ${error}`);
                            setUserForm(userForm => ({...userForm, loading: false}));
                            document.getElementById('hiddenModalClose').click();

                            systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
                            history.push(`/quotes`);
                        }
                    }

                    putContainers();

                    // setCollectionAreaData(collectionAreaData => ({...collectionAreaData, collectionArea: {width: Number(quoteData.collectionAreaWidth),length: Number(quoteData.collectionAreaLength)}, containers: response.collectionAreaData}));
                    // setDoorwayData(response.doorway);
                    // setQuoteSummaryData(response.quoteSummaryData);
                }

                break;
            default:
                setTimeout(() => {
                    nextStepHandler(nextStep);
                    setUserForm(userForm => ({...userForm, loading: false}));
                }, 500);
                break;
        }
    }

    return (
        <MyModal
            title={props.children}
            closeHandler={closeModalHandler}
            submitHandler={(event) => quoteFormHandler(event, currentStep)}
            previousButton={previousButton}
            nextButton={nextButton}
        >
            <div className="row g-0">
                <div className={`${styles.steps} col-lg-5`}>
                    <div className="progress d-lg-none">
                        <div className="progress-bar" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                    <div className="d-none d-lg-block">
                        <Steps 
                            steps={steps}
                            currentStep={currentStep}
                        />
                    </div>
                </div>
                <div className={`${styles.form} col-lg-7`}>
                    {stepComponent}
                </div>
            </div>
        </MyModal>
    )
}

export default NewQuote;