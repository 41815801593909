import React from 'react';

const PageNotFound = () => {
    return (
        <React.Fragment>
            <div className="h2 text-center mt-5 mb-3">
                This page isn't available.
            </div>
            <div className="text-muted text-center">
                The link you followed may be broken, or the page may have been removed.
            </div>
        </React.Fragment>
    )
}

export default PageNotFound;