import React from 'react';
import CheckBox from '../../UI/CheckBox';
import SectionTitle from '../../Typography/SectionTitle';

const Services = ({ formData, changeHandler }) => {
    const formElementsArray = [];

    for (let key in formData.options) {
        formElementsArray.push({
            id: key,
            name: formData.options[key]['name'],
            checked: formData.options[key]['checked']
        });
    }

    let formElems = null;

    formElems = formElementsArray.map((formElement, index) => {
        return (
            <div key={index} className="card py-2 px-3 mb-3">
                <CheckBox
                    id={formElement.id}
                    label={formElement.name}
                    value={formElement.id}
                    checked={formElement.checked}
                    changed={(event) => changeHandler(event, formElement.id)}
                />
            </div>
        )
    });

    return (
        <React.Fragment>
            <SectionTitle>
                Choose your services
            </SectionTitle>
            <div className="WS-subTitle mb-2">Services</div>
            {formElems}
        </React.Fragment>
    )
}

export default Services;