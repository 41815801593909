import React, { useContext } from 'react';
import styles from './SystemMessage.module.css';
import SystemMessageContext from '../../store/system-message-context';

const SystemMessage = () => {
    const systemMessageCtx = useContext(SystemMessageContext); 

    const messageText = systemMessageCtx.text;
    const messageStatus = systemMessageCtx.status;
    let displayClass = 'd-flex';

    if (messageText === '') {
        displayClass = 'd-none';
    }

    return (
        <div className={`${styles.alert} ${styles[messageStatus]} ${displayClass}`} role="alert">
            <div className="d-flex align-items-center">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-2 bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                    </svg>
                </div>
                <div className="me-2">
                    {messageText}
                </div>
            </div>
            <button type="button" className="btn WS-btn-round WS-btn-round-trans" onClick={(event) => systemMessageCtx.clearSystemMessage('', '')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                </svg>
            </button>
        </div>
    )
}

export default SystemMessage;