import React from 'react';
import SectionTitle from '../../Typography/SectionTitle';
import InputGroup from '../../UI/InputGroup';

const CollectionAreaInfo = ({ formData, changeHandler }) => {
    return (
        <React.Fragment>
            <SectionTitle>
                Collection area dimensions
            </SectionTitle>
            <InputGroup
                type="number"
                id="collectionAreaWidth"
                label="Collection area width"
                helpText={`Please enter the approximate width of the collection area. A default value has been preassigned.`}
                helpTextId="collectionAreaWidth-help"
                addOnText="Feet"
                value={formData.formFields.collectionAreaWidth.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="collectionAreaLength"
                label="Collection area length"
                helpText={`Please enter the approximate length of the collection area.  A default value has been preassigned`}
                helpTextId="collectionAreaLength-help"
                addOnText="Feet"
                value={formData.formFields.collectionAreaLength.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="collectionAreaHeight"
                label="Collection area height (Optional)"
                helpText={`If there are any height restrictions for your collection area (indoors, enclosed in a cage, etc), please enter the approximate height of the collection area.`}
                helpTextId="collectionAreaHeight-help"
                addOnText="Feet"
                value={formData.formFields.collectionAreaHeight.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
        </React.Fragment>
    )
}

export default CollectionAreaInfo;