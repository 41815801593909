import React from 'react';

const CheckBox = (props) => {
    return (
        <div className="form-check">
            <input 
                    className="form-check-input"
                    type="checkbox"
                    id={props.id}
                    {...props.attributes}
                    value={props.value}
                    checked={props.checked}
                    onChange={props.changed}
                />
            <label className="form-check-label w-100" htmlFor={props.id}>{props.label}</label>
        </div>
    )
}

export default CheckBox;