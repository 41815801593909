import React from 'react';
import currency from 'currency.js';
import { getNested } from '../../utils';

const ContainerPricingTableRow = (props) => {
    const pickupFrequency = props.pickupFrequency;
    const costPerPickup = props.costPerPickup;
    const volume = props.volume;
    const volumeUnit = props.volumeUnit;
    const numBins = props.numBins;
    const binsDiscount = props.numBinsDiscount;
    const numPickupsDiscount = props.numPickupsDiscount;

    let monthlyCostColumns = null;
    let costPerYardColumns = null;

    monthlyCostColumns = pickupFrequency.map((pickup, index) => {
        const pickupFrequencyDiscount = getNested(numPickupsDiscount, pickup.id);
        const monthlyCost = (((costPerPickup * numBins) * binsDiscount) * pickupFrequencyDiscount) * pickup.priceMultiplier;
        
        return (
            <td key={index}>{currency(monthlyCost).format()}</td>
        )
    });

    costPerYardColumns = pickupFrequency.map((pickup, index) => {
        const pickupFrequencyDiscount = getNested(numPickupsDiscount, pickup.id);
        const costPerYard = ((costPerPickup * binsDiscount) * pickupFrequencyDiscount) / volume;

        return (
            <td key={index}>{currency(costPerYard).format()}</td>
        )
    });
    
    let pickupFrequencyDiscount = null;

    pickupFrequencyDiscount = pickupFrequency.map((pickup, index) => {
        const pickupFrequencyDiscount = getNested(numPickupsDiscount, pickup.id);
        const convertedDiscount = Math.round((1 - pickupFrequencyDiscount) * 100);

        return (
            <td key={index}>{convertedDiscount}%</td>
        )
    });

    const monthlyCost = (costPerPickup * numBins) * binsDiscount;
    const costPerYard = (costPerPickup * binsDiscount) / volume;
    const convertedBinsDiscount = Math.round((1 - binsDiscount) * 100);

    return (
        <React.Fragment>
            <tr>
                <th scope="row" rowSpan="3">{numBins}</th>
                <td>{convertedBinsDiscount}%</td>
                <th scope="row" className="table-success">{currency(monthlyCost).format()}</th>
                {monthlyCostColumns}
            </tr>
            <tr className="table-info">
                <th scope="row" className="text-nowrap">Cost / {volumeUnit}</th>
                <td>{currency(costPerYard).format()}</td>
                {costPerYardColumns}
            </tr>
            <tr className="table-light">
                <th scope="row" colSpan="2" className="text-nowrap">Pick up frequency discount</th>
                {pickupFrequencyDiscount}
            </tr>
        </React.Fragment>
    )
}

export default ContainerPricingTableRow;