// import React, { useState } from 'react';
import React from 'react';
import styles from './ContainerCard.module.css';
import ContainerPricingTable from './ContainerPricingTable';
import ContainerPricingForm from './ContainerPricingForm';

const ContainerCard = (props) => {
    // const [editToggle, setEditToggle] = useState(false);
    const editToggle = false;

    const service = props.service;
    const name = props.name;
    const category = props.category;
    const volume = props.volume;
    const volumeUnit = props.volumeUnit;
    const pickupFrequency = props.pickupFrequency;
    const serviceId = props.serviceId;
    const containerId = props.containerId;

    return (
        <div className="WS-card">
            <div className="d-flex justify-content-between">
                <div className="h6">
                    {name}
                </div>
                <div className="d-flex">
                    {/* <button type="button" className="btn WS-btn-round WS-btn-round-trans" onClick={() => setEditToggle(!editToggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                    </button> */}
                    {/* <button type="button" className="btn WS-btn-round WS-btn-round-trans ms-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        </svg>
                    </button> */}
                </div>
            </div>
            <div className={`${styles.subtitle}`}>
                Service: {service}
            </div>
            <div className={`${styles.subtitle}`}>
                Category: {category}
            </div>
            <div className={`${styles.subtitle} mb-3`}>
                Volume: {volume} {volumeUnit}s
            </div>
            {!editToggle
                ? <ContainerPricingTable 
                    serviceId={serviceId}
                    containerId={containerId}
                    pickupFrequency={pickupFrequency}
                    volume={volume}
                    volumeUnit={volumeUnit}
                />
                : <ContainerPricingForm 
                    serviceId={serviceId}
                    containerId={containerId}
                    pickupFrequency={pickupFrequency}
                    volume={volume}
                    volumeUnit={volumeUnit}
                />
            }
        </div>
    )
}

export default ContainerCard;