import React from 'react';
import styles from './Footer.module.css';
// import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <ul className={styles.left}>
                {/* <li>
                    <Link to="/feedback">Feedback</Link>
                </li> */}
            </ul>
            <ul className={styles.right}>
                <li className={styles.copy}>
                    &copy; {new Date().getFullYear()} WasteSaver
                </li>
                {/* <li>
                    <Link to="/privacy">Privacy</Link>
                </li>
                <li>
                    <Link to="/terms">Terms</Link>
                </li> */}
            </ul>
        </div>
        
    )
}

export default Footer;