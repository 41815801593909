import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QuotesTable from '../components/Quotes/QuotesTable';
import ContentTitle from '../components/Typography/ContentTitle';
import NewQuote from '../components/Quotes/NewQuote/NewQuote';
import Preloader from '../components/UI/Preloader';

const Quotes = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [quotes, setQuotes] = useState();

    useEffect(() => {
        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

        const getQuotes = async () => {
            try {
                const provider_id = '4594c7bd-8cf2-4858-bd28-69ddc385d693';
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quotes/?providerId=${provider_id}`);

                setQuotes(data.body);
                setIsLoading(false);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getQuotes();
    }, []);
    
    return (
        <React.Fragment>
            <NewQuote 
                title="New quote"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check me-2" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                </svg>
                New quote
            </NewQuote>
            <div className="d-flex justify-content-between mb-4">
                <ContentTitle>
                    Quotes
                </ContentTitle>
                <div>
                    <button type="button" className="btn btn-primary d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#defaultModal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check me-2" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                        New quote
                    </button>
                </div>
            </div>
            <Preloader
                loading={isLoading}
            >
                <QuotesTable 
                    quotes={quotes}
                />
            </Preloader>
            
        </React.Fragment>
    )
}

export default Quotes;