import React from 'react';

const Select = (props) => {
    let options = null;
    let defaultOption = null;
    const swatch = props.swatch;

    if (props.defaultOption) {
        defaultOption = <option value={props.defaultOption.value}>{props.defaultOption.name}</option>
    }

    if (Array.isArray(props.options)) {
        options = props.options.map((option, index) => {
            return (
                <option key={index} value={option.id}>{option.name}</option>
            )
        });
    }   

    let myComponentStyle = {};

    // if (swatch !== undefined) {
    //     myComponentStyle = {
    //         borderLeft: `solid 3px ${swatch}`,
    //         paddingLeft: '7px'
    //     }
    // }

    if (swatch !== undefined) {
        myComponentStyle = {
            width: '15px', 
            height: '15px', 
            backgroundColor: `${swatch}`, 
            marginRight: '0.5rem', 
            borderRadius: '0.25rem'
        }
    }
     
    return (
        // <div className="mb-3" style={myComponentStyle}>
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label d-flex align-items-center">
                <div style={myComponentStyle}></div>
                {props.label}
            </label>
            <select 
                id={props.id} 
                {...props.attributes}
                className="form-select" 
                value={props.value}
                onChange={props.changed}
            >
                {defaultOption}
                {options}
            </select>
            <div id={props.helpTextId} className="form-text">{props.helpText}</div>
        </div>
    )
}

export default Select;