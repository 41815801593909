import React from 'react';
import Menu from './Menu';
import styles from './Drawer.module.css';

const Drawer = () => {
    const offcanvasDataAttributeToggle = 'offcanvas';
    const offcanvasDataAttributeTarget = '#offcanvasDrawer';

    return (
        <div className={`${styles.drawer} offcanvas offcanvas-start`} tabIndex="-1" id="offcanvasDrawer">
            <div className={`${styles.header}`}>
                WasteSaver
                <div className={styles['beta-main']}>
                    <span className={`${styles.beta} badge bg-light text-dark`}>BETA</span>
                </div>
            </div>
            <Menu 
                offcanvasDataAttributeToggle={offcanvasDataAttributeToggle}
                offcanvasDataAttributeTarget={offcanvasDataAttributeTarget}
            />
        </div>
    )
}

export default Drawer;