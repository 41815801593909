import React from 'react';
import Container from './Container';
import './AccessArea.css';
import { colorPalette } from '../../utils';

const AccessArea = (props) => {
    const maxWidthPx = props.maxWidth;
    const unitPxConversion = maxWidthPx / props.collectionAreaData.collectionArea.width;
    const maxHeightPx = props.collectionAreaData.collectionArea.length * unitPxConversion;

    const containers = props.collectionAreaData.containers.map((container, index) => {
        let bgColour = '#eee';

        if (container.isFilled) {
            if (container.palette) {
                bgColour = colorPalette(container.palette, 1);
            } else if (container.hex) {
                bgColour = container.hex;
            }            
        }

        return <Container
            key={index}
            containerName={container.name}
            serviceTypeName={container.serviceTypeName}
            x={container.x * unitPxConversion}
            y={container.y * unitPxConversion}
            width={container.width * unitPxConversion}
            length={container.length * unitPxConversion}
            manoeuvreFactor={container.manoeuvreFactor}
            actualWidth={container.width}
            actualLength={container.length}
            color={bgColour}
        />
    });

    const doorwayPosition = props.doorwayData.position;
    const doorwayTitle = `Doorway: ${props.doorwayData.width}' W x ${props.doorwayData.height}' H`;
    const doorwayDisplayLength = 8;
    let doorwayDisplayWidthPx = null;
    let doorwayDisplayHeightPx = null;
    let doorwayPositionTop = null;
    let doorawyPositionLeft = null;

    switch(doorwayPosition) {
        case 'top':
            doorwayDisplayWidthPx = props.doorwayData.width * unitPxConversion;
            doorwayDisplayHeightPx = doorwayDisplayLength;
            doorwayPositionTop = 0 - doorwayDisplayLength;
            doorawyPositionLeft = (maxWidthPx - doorwayDisplayWidthPx) / 2;
            break;
        case 'right':
            doorwayDisplayWidthPx = doorwayDisplayLength;
            doorwayDisplayHeightPx = props.doorwayData.width * unitPxConversion;
            doorwayPositionTop = (maxHeightPx - doorwayDisplayHeightPx) / 2;
            doorawyPositionLeft = maxWidthPx;
            break;
        case 'bottom':
            doorwayDisplayWidthPx = props.doorwayData.width * unitPxConversion;
            doorwayDisplayHeightPx = doorwayDisplayLength;
            doorwayPositionTop = maxHeightPx;
            doorawyPositionLeft = (maxWidthPx - doorwayDisplayWidthPx) / 2;
            break;
        case 'left':
            doorwayDisplayWidthPx = doorwayDisplayLength;
            doorwayDisplayHeightPx = props.doorwayData.width * unitPxConversion;
            doorwayPositionTop = (maxHeightPx - doorwayDisplayHeightPx) / 2;
            doorawyPositionLeft = 0 - doorwayDisplayWidthPx;
            break;
        default:
            break;
    }

    return (
        <div className={props.displayClass}>
            <div className="alert alert-info d-flex align-items-start" role="alert">
                <div className="me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                </div>
                <div>
                    WasteSaver has applied a movement factor to the footprint of each container when evaluating the storage area required to meet weekly volume estimates for this quote.
                </div>
            </div>
            <div className="h5 text-center">{props.collectionAreaData.collectionArea.width}'</div>
            <div className="AccessArea-rotated-heading">
                <div className="AccessArea-access-area" style={{
                    width: `${maxWidthPx}px`,
                    height: `${maxHeightPx}px`
                }}>
                    {containers}
                    <h5 style={{
                        top: `${((maxHeightPx) / 2) + 10}px`
                    }}>
                        {props.collectionAreaData.collectionArea.length}'
                    </h5>
                    {doorwayPosition && 
                        <div title={doorwayTitle} className="AccessArea-doorway" style={{
                            top: `${doorwayPositionTop}px`,
                            left: `${doorawyPositionLeft}px`,
                            width: `${doorwayDisplayWidthPx}px`,
                            height: `${doorwayDisplayHeightPx}px`
                        }}></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AccessArea;