import React, { useState, useEffect, useContext } from 'react';
import SystemMessageContext from '../store/system-message-context';
import axios from 'axios';
import Button from '../components/UI/Button/Button';
import AccessArea from '../components/Quotes/AccessArea';
import QuoteSummary from '../components/Quotes/QuoteSummary';
import './Quotes.css';
import { getBestQuote } from '../binPacking';
import ContentTitle from '../components/Typography/ContentTitle';
import SectionTitle from '../components/Typography/SectionTitle';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from '../components/UI/Preloader';
import ServiceDetails from '../components/EditQuote/ServiceDetails';
import CustomerDetails from '../components/EditQuote/CustomerDetails';

const Quotes = () => {
    const systemMessageCtx = useContext(SystemMessageContext);
    const PROVIDER_ID = '4594c7bd-8cf2-4858-bd28-69ddc385d693';

    const [isLoading, setIsLoading] = useState(true);
    const [quoteIsLoading, setQuoteIsLoading] = useState(false);
    const [containerData, setContainerData] = useState({});
    const [priceData, setPriceData] = useState({});
    const [pickupFrequencyData, setPickupFrequencyData] = useState({});
    const [quoteData, setQuoteData] = useState({});
    const { quoteId } = useParams();
    const [collectionAreaData, setCollectionAreaData] = useState({
        collectionArea: {
            width: 20,
            length: 15
        },
        containers: []
    });
    const [doorwayData, setDoorwayData] = useState({});
    const [quoteSummaryData, setQuoteSummaryData] = useState([]);

    // console.log(`quoteId: ${quoteId}`);

    const history = useHistory();

    const detailClickHandler = (event) => {
        history.push('/quotes');
    }

    useEffect(() => {
        const elem = document.getElementById('content');
        elem.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

        const getContainerData = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/containers/?providerId=${PROVIDER_ID}`);
      
                setContainerData(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        const getPriceData = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/pricing/?providerId=${PROVIDER_ID}`);
                
                setPriceData(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        const getPickupFrequencyData = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/pickup-frequency/?providerId=${PROVIDER_ID}`);

                setPickupFrequencyData(data.body);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        const getQuote = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quotes/${quoteId}`);

                const tmpQuoteData = data.body[0];
                // console.log(tmpQuoteData);

                if (tmpQuoteData === undefined) {
                    history.push('/quotes');
                } else {
                    const doorwayObj = {
                        position: tmpQuoteData.doorwayPosition,
                        width: tmpQuoteData.doorwayWidth,
                        height: tmpQuoteData.doorwayHeight
                    };

                    setQuoteData(tmpQuoteData);
                    setCollectionAreaData(collectionAreaData => ({...collectionAreaData, collectionArea: {width: Number(tmpQuoteData.collectionAreaWidth),length: Number(tmpQuoteData.collectionAreaLength)}, containers: tmpQuoteData.containers}));
                    setDoorwayData(doorwayObj);
                    setQuoteSummaryData(tmpQuoteData.quoteSummaryData);
                    getContainerData();
                    getPriceData();
                    getPickupFrequencyData();
                    setIsLoading(false);
                }
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getQuote();
    }, [PROVIDER_ID, quoteId, history]);

    const inputChangedHandler = (event, inputId) => {
        const type = event.target.type;
        let newValue = event.target.value;

        const updatedForm = {...quoteData};

        if (type === 'number') {
            if (newValue <= 0) {
                newValue = '';
            }
            updatedForm[inputId] = Number(newValue);
        } else {
            updatedForm[inputId] = newValue;
        }

        setQuoteData(updatedForm);
    }

    const serviceInputChangedHandler = (event, serviceId) => {
        const type = event.target.type;
        let newValue = event.target.value;
        const updatedForm = [...quoteData.services];
        
        for (const service of updatedForm) {
            // console.log(service.serviceId);
            // console.log(service.weeklyVolume);
            // console.log(service.pickupFrequencyId);
            
            if (service.serviceId === serviceId) {
                if (type === 'number') {
                    if (newValue <= 0) {
                        newValue = '';
                    }
                    service.weeklyVolume = newValue;
                } else if (type === 'select-one') {
                    service.pickupFrequencyId = newValue;
                }
            }
        }

        setQuoteData(quoteData => ({...quoteData, services: updatedForm}));
    }

    // handle form submission
    const demoFormHandler = (event) => {
        event.preventDefault();
        
        // setDemoForm(demoForm => ({...demoForm, loading: true}));
        setQuoteIsLoading(true);

        console.log('============= FORM SUBMITTED =============');

        let putServicesArr = [];
        let services = [];

        for (const service of quoteData.services) {
            putServicesArr.push({
                id: service.serviceId,
                volume: Number(service.weeklyVolume),
                pickupFrequency: service.pickupFrequencyId
            });

            services.push({
                id: service.serviceId,
                name: service.name,
                volumePerWeek: Number(service.weeklyVolume),
                palette: service.palette,
                pickupFrequencyId: service.pickupFrequencyId
            });
        }
        
        // UPDATE QUOTE DATA
        const putQuote = async () => {
            const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

            const putData = {
                quoteId: quoteId,
                customerName: quoteData.customerName,
                customerNumber: quoteData.customerNumber,
                formattedAddress: quoteData.formattedAddress,
                lat: quoteData.lat,
                lng: quoteData.lng,
                collectionAreaWidth: Number(quoteData.collectionAreaWidth),
                collectionAreaLength: Number(quoteData.collectionAreaLength),
                collectionAreaHeight: Number(quoteData.collectionAreaHeight),
                doorwayPosition: quoteData.doorwayPosition,
                doorwayWidth: Number(quoteData.doorwayWidth),
                doorwayHeight: Number(quoteData.doorwayHeight),
                services: putServicesArr
            };

            try {
                const { data } = await axios.put(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quotes`, putData);

                // validate response
                if (data.statusCode === 200) {
                    updateQuoteSummary();
                }
            } catch(error) {
                console.log(`error: ${error}`);
                setQuoteIsLoading(false);

                systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
            }
        }

        putQuote();
        
        // UPDATE QUOTE SUMMARY AND CONTAINER DATA
        const updateQuoteSummary = async () => {
            const customerData = {
                collectionArea: {
                    width: Number(quoteData.collectionAreaWidth),
                    length: Number(quoteData.collectionAreaLength),
                    height: Number(quoteData.collectionAreaHeight)
                },
                doorway: {
                    position: quoteData.doorwayPosition,
                    width: Number(quoteData.doorwayWidth),
                    height: Number(quoteData.doorwayHeight)
                },
                serviceTypes: services
            };
            
            // RUN BIN PACKING ALGORITHM
            const response = getBestQuote(customerData, containerData, priceData, pickupFrequencyData);
           
            const putContainers = async () => {
                const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

                const containerNodes = response.collectionAreaData;
                const containers = containerNodes.filter(item => item.id !== null && item.id !== 'reserved');
                const reservedNodes = containerNodes.filter(item => item.id === 'reserved');

                const putData = {
                    quoteId: quoteId,
                    containers: containers,
                    reservedNodes: reservedNodes
                };
                
                // console.log(putData);

                try {
                    const { data } = await axios.put(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quote-containers`, putData);
    
                    // validate response
                    if (data.statusCode === 200) {
                        putSummary();
                    }
                } catch(error) {
                    console.log(`error: ${error}`);
                    setQuoteIsLoading(false);
    
                    systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
                }
            }

            const putSummary = async () => {
                const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

                const services = response.quoteSummaryData;

                const putData = {
                    quoteId: quoteId,
                    services: services
                };
                
                // console.log(putData);

                try {
                    const { data } = await axios.put(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/quote-summary`, putData);
    
                    // validate response
                    if (data.statusCode === 200) {
                        setCollectionAreaData(collectionAreaData => ({...collectionAreaData, collectionArea: {width: Number(quoteData.collectionAreaWidth),length: Number(quoteData.collectionAreaLength)}, containers: response.collectionAreaData}));
                        setDoorwayData(response.doorway);
                        setQuoteSummaryData(response.quoteSummaryData);
                        setQuoteIsLoading(false);
                        systemMessageCtx.setSystemMessage('Your quote was updated successfully.', 'success');
                    }
                } catch(error) {
                    console.log(`error: ${error}`);
                    setQuoteIsLoading(false);
    
                    systemMessageCtx.setSystemMessage('There was an error processing your request.', 'danger');
                }
            }

            putContainers();
        }
    }

    return (
        <Preloader
            loading={isLoading}
        >
            <React.Fragment>
                <div className="d-flex align-items-center mb-4">
                    <div>
                        <Button
                            classes="btn btn-sm btn-light ps-0"
                            type="button"
                            loading={false}
                            valid={true}
                            clickHandler={detailClickHandler}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                            </svg>
                        </Button>
                    </div>
                    <ContentTitle>
                        {quoteData.customerName}
                    </ContentTitle>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <SectionTitle>
                            Quote summary
                        </SectionTitle>
                        <QuoteSummary
                            quoteSummaryData={quoteSummaryData}
                        />
                        <SectionTitle>
                            Equipment breakdown
                        </SectionTitle>
                        <AccessArea 
                            maxWidth={475}
                            displayClass="d-none d-xl-block"
                            collectionAreaData={collectionAreaData}
                            doorwayData={doorwayData}
                        />
                        <AccessArea 
                            maxWidth={365}
                            displayClass="d-none d-md-block d-xl-none d-xxl-none"
                            collectionAreaData={collectionAreaData}
                            doorwayData={doorwayData}
                        />
                        <AccessArea 
                            maxWidth={300}
                            displayClass="d-md-none"
                            collectionAreaData={collectionAreaData}
                            doorwayData={doorwayData}
                        />
                    </div>
                    <div className="col-lg-5">
                        <form onSubmit={demoFormHandler} noValidate>
                            <CustomerDetails 
                                quoteData={quoteData}
                            />
                            <ServiceDetails 
                                quoteData={quoteData}
                                pickupFrequencyData={pickupFrequencyData}
                                changeHandler={inputChangedHandler}
                                serviceChangedHandler={serviceInputChangedHandler}
                            />
                            <div className="mt-4 mb-5">
                                <Button
                                    classes="btn btn-primary WS-btn-min-width"
                                    type="submit"
                                    loading={quoteIsLoading}
                                    valid={true}
                                >
                                    Refresh quote
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        </Preloader>
    )
}

export default Quotes;