import React from 'react';
import InputGroup from '../../UI/InputGroup';
import Select from '../../UI/Select';
import SectionTitle from '../../Typography/SectionTitle';

const ServiceInfo = ({ formData, changeHandler }) => {
    const formElementsArray = [];

    for (let key in formData.options) {
        if (formData.options[key]['checked']) {
            formElementsArray.push({
                id: key,
                name: formData.options[key]['name'],
                volume: formData.options[key]['volume'],
                pickupFrequency: formData.options[key]['pickupFrequency'],
                pickupFrequencyOptions: formData.options[key]['pickupFrequencyOptions']
            });
        }
    }

    let volumeFormElems = null;

    volumeFormElems = formElementsArray.map((formElement, index) => {
        const attributes = {
            'aria-describedby': `volume-${formElement.id}-help`
        };

        return (
            <InputGroup
                key={index}
                type="number"
                attributes={attributes}
                id={`volume-${formElement.id}`}
                label={formElement.name}
                addOnText="Cubic yards"
                helpText={`Please enter the estimated weekly volume for ${formElement.name}.`}
                helpTextId={`volume-${formElement.id}-help`}
                value={formElement.volume}
                changed={(event) => changeHandler(event, formElement.id)}
            />
        )
    });

    const defaultOption = {
        value: '',
        name: 'Any'
    };

    let pickupFrequencyFormElems = null;
    
    pickupFrequencyFormElems = formElementsArray.map((formElement, index) => {
        const attributes = {
            'aria-describedby': `pickup-${formElement.id}-help`
        };

        return (
            <Select
                key={index}
                attributes={attributes}
                id={`pickup-${formElement.id}`}
                label={formElement.name}
                helpText={`Please choose your preferred pick up frequency for ${formElement.name}.`}
                helpTextId={`pickup-${formElement.id}-help`}
                options={formElement.pickupFrequencyOptions}
                defaultOption={defaultOption}
                value={formElement.pickupFrequency}
                changed={(event) => changeHandler(event, formElement.id)}
            />
        )
    });

    return (
        <React.Fragment>
            <SectionTitle>
                Weekly service volume
            </SectionTitle>
            {volumeFormElems}
            <SectionTitle>
                Preferred pick up frequency
            </SectionTitle>
            {pickupFrequencyFormElems}
        </React.Fragment>
    )
}

export default ServiceInfo;