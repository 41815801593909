import React, { useContext } from 'react';
import PricingContext from '../../store/pricing-context';
import ContainerPricingTableRow from './ContainerPricingTableRow';
import currency from 'currency.js';
import { getNested } from '../../utils';
import BlankSlate from '../Typography/BlankSlate'

const ContainerPricingTable = (props) => {
    const ctx = useContext(PricingContext); 
    const serviceId = props.serviceId;
    const containerId = props.containerId;
    const pickupFrequency = props.pickupFrequency;
    const volume = props.volume;
    const volumeUnit = props.volumeUnit;
    const costPerPickup = getNested(ctx.priceData, serviceId, containerId, 'costPerPickup');
    const numPickupsDiscount = getNested(ctx.priceData, serviceId, containerId, 'numPickupsDiscount');
    const numBins = getNested(ctx.priceData, serviceId, containerId, 'numBinsDiscount');

    let pickupFrequencyHeadings = null;

    if (Array.isArray(pickupFrequency)) {
        pickupFrequencyHeadings = pickupFrequency.map((pickup, index) => {
            return (
                <th key={index} scope="col" className="table-light">{pickup.name}</th>
            )
        });
    }

    let numBinsRows = null;

    if (Array.isArray(numBins)) {
        numBinsRows = numBins.map((bins, index) => {
            return <ContainerPricingTableRow
                key={index}
                numBins={bins.numBins}
                numBinsDiscount={bins.numBinsDiscount}
                numPickupsDiscount={bins.numPickupsDiscount}
                pickupFrequency={pickupFrequency}
                costPerPickup={costPerPickup}
                volume={volume}
                volumeUnit={volumeUnit}
            />
        });
    }

    return (
        <React.Fragment>
            {costPerPickup === ''
                ?   
                    <BlankSlate>
                        You haven't set any pricing for this container yet.
                    </BlankSlate>
                :
                    <React.Fragment>
                        <div className="row mb-2">
                            <div className="col-sm mb-3">
                                <div className="WS-subTitle">Base cost per pick up</div>
                                <div className="h5">{currency(costPerPickup).format()}</div>
                            </div>
                            <div className="col-sm mb-4">
                            <div className="WS-subTitle">Base cost per {volumeUnit}</div>
                                <div className="h5">{currency(costPerPickup / volume).format()}</div>
                            </div>
                            <div className="col-sm"></div>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th scope="col" colSpan="3" className="border-0"></th>
                                        <th scope="col" colSpan={pickupFrequency.length} className="table-light">
                                            Total monthly cost by pick up frequency
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="text-nowrap"># bins</th>
                                        <th scope="col" className="text-nowrap"># bins discount</th>
                                        <th scope="col" className="text-nowrap">Cost / pick up</th>
                                        {pickupFrequencyHeadings}
                                    </tr>
                                </thead>
                                <tbody>
                                    <ContainerPricingTableRow
                                        numBins="1"
                                        numBinsDiscount="1"
                                        numPickupsDiscount={numPickupsDiscount}
                                        pickupFrequency={pickupFrequency}
                                        costPerPickup={costPerPickup}
                                        volume={volume}
                                        volumeUnit={volumeUnit}
                                    />
                                    {numBinsRows}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ContainerPricingTable;