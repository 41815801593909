import React from 'react';
import { formatDecimal } from '../../utils';

const Container = (props) => {
    const manoeuvreFactor = props.manoeuvreFactor;
    const top = props.y;
    const left = props.x;
    const width = props.width;
    const length = props.length;
    let actualWidth = formatDecimal(props.actualWidth, 2);
    let actualLength = formatDecimal(props.actualLength, 2);
    let topOffset = 0;
    let leftOffset = 0;
    let title;
    let container;
    
    if(manoeuvreFactor > 0) {

        const adjustedWidth = width / manoeuvreFactor;
        const adjustedLength = length / manoeuvreFactor;
        topOffset = (length - adjustedLength) / 2;
        leftOffset = (width - adjustedWidth) / 2;
        
        const manoeuvreFactorTitle = `Storage area required w / movement factor: ${actualWidth}' W x ${actualLength}' L`;

        actualWidth = actualWidth / manoeuvreFactor;
        actualLength = actualLength / manoeuvreFactor;

        title = `${props.serviceTypeName.toUpperCase()} - ${props.containerName}: ${formatDecimal(actualWidth, 2)}' W x ${formatDecimal(actualLength, 2)}' L`;

        container = (
            <div title={manoeuvreFactorTitle} className="QuoteDemo-storage-area d-flex align-items-center text-truncate" style={{
                top: `${top}px`,
                left: `${left}px`,
                width: `${width}px`,
                height: `${length}px`,
                zIndex: `10`
            }}>
                <div title={title} className="QuoteDemo-container d-flex align-items-center text-truncate" style={{
                    top: `${topOffset}px`,
                    left: `${leftOffset}px`,
                    width: `${adjustedWidth}px`,
                    height: `${adjustedLength}px`,
                    backgroundColor: `${props.color}`,
                    zIndex: `20`
                }}></div>
            </div>
        );

    } else {

        if(props.serviceTypeName === null) {

            title = `Unused area: ${actualWidth}' W x ${actualLength}' L`;
            container = (
                <div title={title} className="QuoteDemo-container d-flex align-items-center text-truncate" style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    width: `${props.width}px`,
                    height: `${props.length}px`,
                    backgroundColor: `${props.color}`
                }}></div>
            );

        } else if (props.serviceTypeName === 'RESERVED') {

            title = `Doorway reserved area: ${actualWidth}' W x ${actualLength}' L`;
            container = (
                <div title={title} className="QuoteDemo-reserved-area d-flex align-items-center text-truncate" style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    width: `${props.width}px`,
                    height: `${props.length}px`,
                    backgroundColor: `${props.color}`
                }}></div>
            );

        } else {

            title = `${props.serviceTypeName.toUpperCase()} - ${props.containerName}: ${actualWidth}' W x ${actualLength}' L`;
            container = (
                <div title={title} className="QuoteDemo-container d-flex align-items-center text-truncate" style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    width: `${props.width}px`,
                    height: `${props.length}px`,
                    backgroundColor: `${props.color}`
                }}></div>
            );

        }

    }

    return (
        <React.Fragment>
            {container}
        </React.Fragment>
    )
}

export default Container;