import React from 'react';
import ContainerCard from './ContainerCard';
import SectionTitle from '../Typography/SectionTitle';

const ServiceCard = (props) => {
    const name = props.name;
    const pickupFrequency = props.pickupFrequency;
    const serviceId = props.serviceId;

    let containers = null;

    containers = props.containers.map((container, index) => {
        return <ContainerCard
            key={index}
            containerId={container.id}
            service={name}
            name={container.name}
            category={container.category}
            volume={container.volume}
            volumeUnit={container.volumeUnit}
            pickupFrequency={pickupFrequency}
            serviceId={serviceId}
        />
    });

    return (
        <React.Fragment>
            <SectionTitle>
                {name} ({containers.length})
            </SectionTitle>
            {containers}
        </React.Fragment>
    )
}

export default ServiceCard;