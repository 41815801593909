import React from 'react';

const Button = (props) => {
    let buttonDisabled = false;

    if (props.loading || !props.valid) {
        buttonDisabled = true;
    }

    return (
        <React.Fragment>
            <button
                className={props.classes}
                type={props.type}
                {...props.attributes}
                disabled={buttonDisabled}
                onClick={props.clickHandler}
            >
                {props.loading
                    ? <span className="spinner-border spinner-border-sm"></span>
                    : props.children
                }
            </button>
        </React.Fragment>
    )
}

export default Button;