// generate a random integer between a minimum and maximum value

export const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const _mergeArrays = (a, b, valueToSort) => {
    const c = [];
  
    while (a.length && b.length) {
        if(valueToSort) {
            c.push(a[0][valueToSort] > b[0][valueToSort] ? b.shift() : a.shift())
        } else {
            c.push(a[0] > b[0] ? b.shift() : a.shift())
        }
    }
  
    //if we still have values, let's add them at the end of `c`
    while (a.length) {
        c.push(a.shift());
    }
    while (b.length) {
        c.push(b.shift());
    }
  
    return c;
}
  
export const mergeSort = (a, valueToSort) => {
    if (a.length < 2) return a;
    const middle = Math.floor(a.length / 2);
    const a_l = a.slice(0, middle);
    const a_r = a.slice(middle, a.length);
    const sorted_l = mergeSort(a_l, valueToSort);
    const sorted_r = mergeSort(a_r, valueToSort);
    return _mergeArrays(sorted_l, sorted_r, valueToSort);
}

// to find the value of a nested key in an object without worrying about Type errors
export const getNested = (obj, ...args) => {
    return args.reduce((obj, level) => obj && obj[level], obj)
}

export const formatDecimal = (number, float) => {
    return Number.parseFloat(number).toFixed(float);
}

export const colorPalette = (hue, shadeIndex) => {
    const palettes = {
        cyan: [
            {
                shade: '100',
                hex: '#b2ebf2'
            },
            {
                shade: '200',
                hex: '#80deea'
            },
            {
                shade: '300',
                hex: '#4dd0e1'
            },
            {
                shade: '400',
                hex: '#26c6da'
            },
            {
                shade: '500',
                hex: '#00bcd4'
            },
            {
                shade: '600',
                hex: '#00acc1'
            },
            {
                shade: '700',
                hex: '#0097a7'
            },
            {
                shade: '800',
                hex: '#00838f'
            },
            {
                shade: '900',
                hex: '#006064'
            }
        ],
        blueGrey: [
            {
                 shade: '100',
                 hex: '#cfd8dc'
             },
             {
                 shade: '200',
                 hex: '#b0bec5'
             },
             {
                 shade: '300',
                 hex: '#90a4ae'
             },
             {
                 shade: '400',
                 hex: '#78909c'
             },
             {
                 shade: '500',
                 hex: '#607d8b'
             },
             {
                 shade: '600',
                 hex: '#546e7a'
             },
             {
                 shade: '700',
                 hex: '#455a64'
             },
             {
                 shade: '800',
                 hex: '#37474f'
             },
             {
                 shade: '900',
                 hex: '#263238'
             }
        ],
        indigo: [
            {
                 shade: '100',
                 hex: '#c5cae9'
             },
             {
                 shade: '200',
                 hex: '#9fa8da'
             },
             {
                 shade: '300',
                 hex: '#7986cb'
             },
             {
                 shade: '400',
                 hex: '#5c6bc0'
             },
             {
                 shade: '500',
                 hex: '#3f51b5'
             },
             {
                 shade: '600',
                 hex: '#3949ab'
             },
             {
                 shade: '700',
                 hex: '#303f9f'
             },
             {
                 shade: '800',
                 hex: '#283593'
             },
             {
                 shade: '900',
                 hex: '#1a237e'
             }
        ],
        blue: [
            {
                 shade: '100',
                 hex: '#bbdefb'
             },
             {
                 shade: '200',
                 hex: '#90caf9'
             },
             {
                 shade: '300',
                 hex: '#64b5f6'
             },
             {
                 shade: '400',
                 hex: '#42a5f5'
             },
             {
                 shade: '500',
                 hex: '#2196f3'
             },
             {
                 shade: '600',
                 hex: '#1e88e5'
             },
             {
                 shade: '700',
                 hex: '#1976d2'
             },
             {
                 shade: '800',
                 hex: '#1565c0'
             },
             {
                 shade: '900',
                 hex: '#0d47a1'
             }
        ],
        lightGreen: [
            {
                 shade: '100',
                 hex: '#dcedc8'
             },
             {
                 shade: '200',
                 hex: '#c5e1a5'
             },
             {
                 shade: '300',
                 hex: '#aed581'
             },
             {
                 shade: '400',
                 hex: '#9ccc65'
             },
             {
                 shade: '500',
                 hex: '#8bc34a'
             },
             {
                 shade: '600',
                 hex: '#7cb342'
             },
             {
                 shade: '700',
                 hex: '#689f38'
             },
             {
                 shade: '800',
                 hex: '#558b2f'
             },
             {
                 shade: '900',
                 hex: '#33691e'
             }
        ],
        orange: [
            {
                 shade: '100',
                 hex: '#ffe0b2'
             },
             {
                 shade: '200',
                 hex: '#ffcc80'
             },
             {
                 shade: '300',
                 hex: '#ffb74d'
             },
             {
                 shade: '400',
                 hex: '#ffa726'
             },
             {
                 shade: '500',
                 hex: '#ff9800'
             },
             {
                 shade: '600',
                 hex: '#fb8c00'
             },
             {
                 shade: '700',
                 hex: '#f57c00'
             },
             {
                 shade: '800',
                 hex: '#ef6c00'
             },
             {
                 shade: '900',
                 hex: '#e65100'
             }
        ],
        yellow: [
            {
                 shade: '100',
                 hex: '#fff9c4'
             },
             {
                 shade: '200',
                 hex: '#fff59d'
             },
             {
                 shade: '300',
                 hex: '#fff176'
             },
             {
                 shade: '400',
                 hex: '#ffee58'
             },
             {
                 shade: '500',
                 hex: '#ffeb3b'
             },
             {
                 shade: '600',
                 hex: '#fdd835'
             },
             {
                 shade: '700',
                 hex: '#fbc02d'
             },
             {
                 shade: '800',
                 hex: '#f9a825'
             },
             {
                 shade: '900',
                 hex: '#f57f17'
             }
        ],
        brown: [
            {
                 shade: '100',
                 hex: '#d7ccc8'
             },
             {
                 shade: '200',
                 hex: '#bcaaa4'
             },
             {
                 shade: '300',
                 hex: '#a1887f'
             },
             {
                 shade: '400',
                 hex: '#8d6e63'
             },
             {
                 shade: '500',
                 hex: '#795548'
             },
             {
                 shade: '600',
                 hex: '#6d4c41'
             },
             {
                 shade: '700',
                 hex: '#5d4037'
             },
             {
                 shade: '800',
                 hex: '#4e342e'
             },
             {
                 shade: '900',
                 hex: '#3e2723'
             }
        ],
        red: [
            {
                 shade: '100',
                 hex: '#ffcdd2'
             },
             {
                 shade: '200',
                 hex: '#ef9a9a'
             },
             {
                 shade: '300',
                 hex: '#e57373'
             },
             {
                 shade: '400',
                 hex: '#ef5350'
             },
             {
                 shade: '500',
                 hex: '#f44336'
             },
             {
                 shade: '600',
                 hex: '#e53935'
             },
             {
                 shade: '700',
                 hex: '#d32f2f'
             },
             {
                 shade: '800',
                 hex: '#c62828'
             },
             {
                 shade: '900',
                 hex: '#b71c1c'
             }
        ],
        teal: [
            {
                 shade: '100',
                 hex: '#b2dfdb'
             },
             {
                 shade: '200',
                 hex: '#80cbc4'
             },
             {
                 shade: '300',
                 hex: '#4db6ac'
             },
             {
                 shade: '400',
                 hex: '#26a69a'
             },
             {
                 shade: '500',
                 hex: '#009688'
             },
             {
                 shade: '600',
                 hex: '#00897b'
             },
             {
                 shade: '700',
                 hex: '#00796b'
             },
             {
                 shade: '800',
                 hex: '#00695c'
             },
             {
                 shade: '900',
                 hex: '#004d40'
             }
        ]
    }
    
    return palettes[hue][shadeIndex].hex;
}