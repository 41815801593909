import React from 'react';
import SectionTitle from '../../Typography/SectionTitle';
import InputGroup from '../../UI/InputGroup';
import Select from '../../UI/Select';

const Access = ({ formData, changeHandler }) => {
    const defaultDoorwayOption = {
        value: '',
        name: 'None'
    };
    
    const doorwayOptions = [
        {
            id: 'top',
            name: 'Top'
        },
        {
            id: 'right',
            name: 'Right'
        },
        {
            id: 'bottom',
            name: 'Bottom'
        },
        {
            id: 'left',
            name: 'Left'
        }
    ];

    return (
        <React.Fragment>
            <SectionTitle>
                Doorway access
            </SectionTitle>
            <Select
                id="doorwayPosition"
                label="Doorway position"
                helpText={`Please choose the position of the doorway, or select 'None' if access to the collection area is not restricted by a doorway.`}
                helpTextId="doorwayPosition-help"
                options={doorwayOptions}
                defaultOption={defaultDoorwayOption}
                value={formData.formFields.doorwayPosition.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="doorwayWidth"
                label="Doorway width (Optional)"
                helpText={`Please enter the approximate width of the doorway.`}
                helpTextId="doorwayWidth-help"
                addOnText="Feet"
                value={formData.formFields.doorwayWidth.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
            <InputGroup
                type="number"
                id="doorwayHeight"
                label="Doorway height (Optional)"
                helpText={`Please enter the approximate height of the doorway.`}
                helpTextId="doorwayHeight-help"
                addOnText="Feet"
                value={formData.formFields.doorwayHeight.value}
                changed={(event) => changeHandler(event, event.target.id)}
            />
        </React.Fragment>
    )
}

export default Access;