import { useState } from 'react';
import SystemMessageContext from './system-message-context';

const SystemMessageProvider = (props) => {
    const [systemMessage, setSystemMessage] = useState({
        text: '',
        status: ''
    });

    const setSystemMessageHandler = (text, status) => {
        setSystemMessage(systemMessage => ({...systemMessage, text: text, status: status}));

        const elem = document.getElementById('content');
        elem.classList.add('WS-content-with-message');
    }

    const clearSystemMessageHandler = () => {
        setSystemMessage(systemMessage => ({...systemMessage, text: '', status: ''}));

        const elem = document.getElementById('content');
        elem.classList.remove('WS-content-with-message');
    }

    const systemMessageContext = {
        text: systemMessage.text,
        status: systemMessage.status,
        setSystemMessage: setSystemMessageHandler,
        clearSystemMessage: clearSystemMessageHandler
    };
    
    return <SystemMessageContext.Provider value={systemMessageContext}>
        {props.children}
    </SystemMessageContext.Provider>
};

export default SystemMessageProvider;