import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServiceCards from '../components/Pricing/ServiceCards';
import PricingContext from '../store/pricing-context';
import ContentTitle from '../components/Typography/ContentTitle';
import Preloader from '../components/UI/Preloader';

const Pricing = () => {
    const PROVIDER_ID = '4594c7bd-8cf2-4858-bd28-69ddc385d693';

    const [isLoading, setIsLoading] = useState(true);
    const [priceData, setPriceData] = useState({});
    const [services, setServices] = useState([]);
    
    useEffect(() => {
        const API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;

        const getPriceData = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/pricing/?providerId=${PROVIDER_ID}`);
                
                // console.log(data.body);

                setPriceData(data.body);
                getServices();
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        const getServices = async () => {
            try {
                const { data } = await axios.get(`https://b0un35mavj.execute-api.us-west-2.amazonaws.com/${API_ENVIRONMENT}/services/${PROVIDER_ID}`);

                setServices(data.body);

                setIsLoading(false);
            } catch(error) {
                console.log(`error: ${error}`);
            }
        }

        getPriceData(); 
    }, []);

    return (
        <PricingContext.Provider 
            value={{
                priceData: priceData
            }}
        >
            <div className="mb-4">
                <ContentTitle>
                    Pricing
                </ContentTitle>
            </div>
            <Preloader
                loading={isLoading}
            >
                <ServiceCards 
                    services={services}
                />
            </Preloader>
        </PricingContext.Provider>
    )
}

export default Pricing;