import React from 'react';

const Preloader = (props) => {
    return (
        <React.Fragment>
            {props.loading 
                ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-secondary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                :
                    props.children
            }
        </React.Fragment>
    )
}

export default Preloader;