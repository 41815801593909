import React from 'react';

const Input = (props) => {
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label">{props.label}</label>
            <input 
                id={props.id} 
                {...props.attributes}
                type={props.type} 
                className="form-control" 
                value={props.value}
                onChange={props.changed}
            />
            <div id={props.helpTextId} className="form-text">{props.helpText}</div>
        </div>
    )
}

export default Input;